import axios from 'axios.js';
import * as types from './users.types';

export const fetchUsers = () => async (dispatch) => {
   dispatch(fetchUsersStart());
   try {
      const response = await axios.get('/users');
      dispatch(fetchUsersSuccess(response.data))
   } catch (error) {
      dispatch(fetchUsersError(error.message));
   }
};

export const fetchUser = (id) => async (dispatch) => {
   dispatch(fetchUsersStart());
   try {
      const response = await axios.get(`/users/${ id }`);
      dispatch(fetchUserSuccess(response.data))
   } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
         dispatch(fetchUsersError(error.response.data.message));
      } else {
         dispatch(fetchUsersError(error.message));
      }

   }
};

export const saveUser = (user) => async (dispatch) => {
   dispatch(fetchUsersStart());
   try {
      if (user._id) {
         await axios.put(`/users/${ user._id }`, user);
         dispatch(updateUserSuccess(user))
      } else {
         const response = await axios.post(`/users`, user);
         dispatch(addUserSuccess(response.data))
      }
   } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
         dispatch(fetchUsersError(error.response.data.message));
      } else {
         dispatch(fetchUsersError(error.message));
      }
   }
};

const fetchUsersStart = () => ({
   type: types.FETCH_USERS
});

const fetchUsersSuccess = (items) => ({
   type: types.FETCH_USERS_SUCCESS,
   items
});

const fetchUserSuccess = (item) => ({
   type: types.FETCH_USER_SUCCESS,
   item
});

const updateUserSuccess = (item) => ({
   type: types.UPDATE_USER_SUCCESS,
   item
});

const addUserSuccess = (item) => ({
   type: types.ADD_USER_SUCCESS,
   item
});

const fetchUsersError = (error) => ({
   type: types.FETCH_USERS_ERROR,
   error
});
