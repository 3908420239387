import React from 'react';
import { Row, Col } from 'reactstrap';

import JobCard from './JobCard';

export default function JobsCard({ jobs }) {

   return (
      <Row>
         {jobs && jobs.map(job =>
            <Col md={6} key={job.DocumentID} >
               <JobCard job={job} />
            </Col>
         )}
      </Row>
   )
}
