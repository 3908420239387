import React from 'react';
import { useSelector } from 'react-redux';

import WorkerCard from '../WorkerCard';
import WorkHistoryForm from './WorkHistoryForm';

export default function WorkHistoryCard(props) {
   const cardName = 'work_history';
   const cardTitle = 'Work History';

   const worker = useSelector(state => state.workers.selected);
   const workHistory = worker ? worker[cardName] : [];

   const WorkHistoryItem = ({ item }) => {
      return (
         <div>
            <h5>{item.position}</h5>
            <p>{item.company.toUpperCase()}</p>
            <p>{item.started} - {item.ended || 'Present'}</p>
            <p>{item.extra}</p>
         </div>
      )
   }
   return (
         <WorkerCard
            title={cardTitle}
            cardName={cardName}
            dataArray={workHistory}
            ItemInfo={WorkHistoryItem}
            Form={WorkHistoryForm}
         />
   )
}
