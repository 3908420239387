import React, { useContext } from "react";
import { FieldArray } from "react-final-form-arrays";
import { add, addDays, addHours, getDate, getHours, getMonth, set, startOfDay } from "date-fns";
import { Field } from "react-final-form";
import { ComposeValidators, Required } from "validators";
import DatePicker from "react-datepicker";
import FormInputSimple from "components/form/FormInputSimple";
import NumberFormat from "react-number-format";
import { Button } from "reactstrap";

import ReadOnlyContext from "components/ReadOnlyContext";

function addNewShift(push, values) {
   if (values.shifts && values.shifts.length) {
      const lastShift = values.shifts[values.shifts.length - 1];
      push('shifts', {
         start: addDays(new Date(lastShift.start), 1),
         end: addHours(addDays(new Date(lastShift.start), 1), 1),
         minutes: 60
      })
   } else {
      push('shifts', {
         start: add(startOfDay(new Date()), { hours: 8 }),
         end: add(startOfDay(new Date()), { hours: 9 }),
         minutes: 60
      })
   }
}

export function formatMinutes(minutes) {
   if (minutes === undefined) {
      return '0 h'
   }
   const hours = minutes / 60;
   const rhours = Math.floor(hours);
   const rminutes = Math.round((hours - rhours) * 60);
   if (rminutes > 0) {
      return `${ rhours }h ${ rminutes }m`;
   }
   return `${ rhours }h`;
}

export function parseMinutes(value) {
   const regex = RegExp(
      `^(((2[0-4]|1[0-9]|[1-9])[h])[ ]([1-5]?[0-9])[m])|(^(2[0-4]|1[0-9]|[1-9])[h])$`
   );

   if (regex.test(value)) {
      const h = value.split(" ")[0].replace("h", "");
      const m = value.split(" ")[1] ? value.split(" ")[1].replace("m", "") : 0;
      const totalMinutes = +h * 60 + +m;

      return totalMinutes;
   }
   return 0;
}

export default function JobShifts({ paymentType, hourlyRate, pop, push, values }) {
   const isReadOnly = useContext(ReadOnlyContext);

   return (
      <>
         <h3 style={ { marginBottom: '0px' } }>Shifts</h3>
         <hr/>
         <FieldArray name="shifts">
            {
               ({ fields }) => fields.map((name, index) => {
                  const { start, end, amount, minutes, minutesStr } = fields.value[index];
                  const shiftStart = start && start.toDate ? start.toDate() : start;
                  const shiftEnd = end && end.toDate ? end.toDate() : end;

                  function includeTimes() {
                     const list = [];
                     for (let i = 1; i <= 24; i++) {
                        list.push(set(shiftStart, { hours: getHours(shiftStart) + i, minutes: 0 }));
                        if (i < 24) {
                           list.push(
                              set(shiftStart, { hours: getHours(shiftStart) + i, minutes: 30 })
                           );
                        }
                     }

                     return list.filter(
                        l => getDate(l) === getDate(shiftEnd) && getMonth(l) === getMonth(shiftEnd)
                     );
                  }

                  return (
                     <div className="shift-row" key={ name }>
                        <div>
                           <Field name={ `${ name }.start` }
                                  validate={ ComposeValidators(Required) }
                                  format={ value => (value ? value && value.toDate ? value.toDate() : value : null) }>
                              {
                                 ({ input: { value, onChange } }) => {
                                    return (
                                       <DatePicker
                                          selected={ value }
                                          onChange={ onChange }
                                          selectsStart
                                          startDate={ value }
                                          endDate={ shiftEnd }
                                          className="form-control date"
                                          showTimeSelect
                                          dateFormat="MM/dd/yyyy h:mm aa"
                                          timeIntervals={ 30 }
                                          placeholderText="Shift Start"
                                          readOnly={isReadOnly}
                                       />
                                    )
                                 }
                              }
                           </Field>
                        </div>
                        <div>
                           <Field name={ `${ name }.end` }
                                  validate={ ComposeValidators(Required) }
                                  format={ value => (value ? value && value.toDate ? value.toDate() : value : null) }>
                              {
                                 ({ input: { value, onChange } }) => {
                                    return (
                                       <DatePicker
                                          selected={ value }
                                          onChange={ onChange }
                                          selectsEnd
                                          startDate={ shiftStart }
                                          endDate={ shiftEnd }
                                          minDate={ shiftStart }
                                          maxDate={ addHours(shiftStart, 24) }
                                          className="form-control date"
                                          showTimeSelect
                                          dateFormat="MM/dd/yyyy h:mm aa"
                                          timeIntervals={ 30 }
                                          includeTimes={ includeTimes() }
                                          placeholderText="Shift End"
                                          readOnly={isReadOnly}
                                       />
                                    )
                                 }
                              }
                           </Field>
                        </div>
                        <div>
                           <FormInputSimple
                              name={ `${ name }.minutesStr` }
                              disabled={ paymentType === 'byShift' }
                              defaultValue={ minutesStr ? minutesStr : minutes ? formatMinutes(minutes) : '' }
                              format={ value => value === undefined ? formatMinutes(fields.value[index].minutes) : value }
                              validate={ ComposeValidators(Required) }
                              readOnly={isReadOnly}
                           />
                        </div>
                        <div>
                           <Field name={ `${ name }.amount` }
                                  defaultValue={ amount ? amount : minutes ? (hourlyRate / 60) * minutes : undefined }
                                  validate={ ComposeValidators(Required) }>
                              {
                                 ({ input: { value, onChange } }) => (
                                    <NumberFormat
                                       decimalScale={ 2 }
                                       fixedDecimalScale={ true }
                                       allowNegative={ false }
                                       className="form-control"
                                       thousandSeparator={ true }
                                       disabled={ paymentType === 'hourly' }
                                       prefix={ '$ ' }
                                       value={ value }
                                       onValueChange={ value => onChange(value.floatValue) }
                                       isAllowed={ values => values.floatValue <= 10000 }
                                       readOnly={isReadOnly}
                                    />
                                 )
                              }
                           </Field>
                        </div>
                     </div>
                  );
               })
            }
         </FieldArray>
         <br/>
         {
            !isReadOnly && (
               <>
                  <Button size="sm" onClick={ () => addNewShift(push, values) }>
                     Add Shift
                  </Button>
                  <Button size="sm" onClick={ () => pop('shifts') }>
                     Remove Shift
                  </Button>
               </>
            )
         }
      </>
   );
}
