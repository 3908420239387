import React from 'react';

const Reports = () => {
   return (
      <div>
         Reports
      </div>
   );
};

export default Reports;
