import React from "react";
import { Col, Row } from "reactstrap";

import { chartExample1, chartExample2, chartExample3 } from "charts";
import { ChartStatCard } from "./ChartStatCard";

export function ChartStatCards() {
   return (
      <Row>
         <Col lg="4" sm="6">
            <ChartStatCard value="$34,657"
                           percentage="+18%"
                           bigTitle="total downloads in last 6 years"
                           footerTitle="Financial Statistics"
                           color="success"
                           smallIcon="nc-simple-add"
                           lineData={chartExample1} />
         </Col>
         <Col lg="4" sm="6">
            <ChartStatCard value="169"
                           percentage="-14%"
                           bigTitle="total subscriptions in last 7 days"
                           footerTitle="View all members"
                           color="danger"
                           smallIcon="nc-button-play"
                           lineData={chartExample2} />
         </Col>
         <Col lg="4" sm="12">
            <ChartStatCard value="8,960"
                           percentage="~51%"
                           bigTitle="total downloads in last 6 years"
                           footerTitle="View more details"
                           color="warning"
                           smallIcon="nc-alert-circle-i"
                           lineData={chartExample3} />
         </Col>
      </Row>
   );
}
