import React from 'react';
import { FormInput } from "components/form"
import { DefaultLength, ComposeValidators, Required } from "validators";

function WorkHistoryForm() {
   return (
      <>
         <FormInput validate={ ComposeValidators(Required, DefaultLength) } name="position" label="Position" type="text" />
         <FormInput validate={ ComposeValidators(Required, DefaultLength) } name="company" label="Company" type="text" />
         <FormInput validate={ ComposeValidators(Required, DefaultLength) } name="started" label="Started" type="text" />
         <FormInput validate={ ComposeValidators(Required, DefaultLength) } name="ended" label="Ended" type="text" />
         <FormInput validate={ ComposeValidators(DefaultLength) } name="extra" label="Extra" type="text" />
      </>
   )
}

export default WorkHistoryForm;
