import React from "react";
import { Table } from "reactstrap";

const RowActionsColumn = ({ row, actions }) => {
   return (
      <td style={{ textAlign: 'right' }}>
         {
            actions.map((Component, index) =>
               <Component key={index} row={row} />
            )
         }
      </td>
   );
}

const TableRow = ({ row, fields, actions }) => {
   return (
      <tr>
         { fields.map(field => <td key={field}>{row[field]}</td>) }
         { actions && actions.length > 0 && <RowActionsColumn row={row} actions={actions} /> }
      </tr>
   )
}

const TableCustom = ({ headers = [], fields = [], rows = [], actions }) => {
   return (
      <Table responsive>
         <thead>
         <tr>
            { headers.map(header => <th key={`${header}`}>{header}</th>) }
            { actions && actions.length > 0 ? <th /> : null }
         </tr>
         </thead>
         <tbody>
         {rows.map((row, index) => <TableRow row={row}
                                             fields={fields}
                                             key={index}
                                             actions={actions} />)}
         </tbody>
      </Table>
   );
}


export default TableCustom;
