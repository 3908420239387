import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

import JobForm from '../components/JobForm';

import { UpdateJob } from '../jobs.actions';

export default function JobPage() {
   const { id } = useParams();

   const job = useSelector(state => state.firestore.data.provider_jobs && state.firestore.data.provider_jobs[id]);

   if (!job) {
      return null;
   }

   return (
      <JobForm job={job}
               initialValues={{DocumentID: id, ...job}}
               onSubmit={job => UpdateJob(job)}
               onCancel={() => console.log('cancel...')} />
   )
}
