import React from 'react';
import { useSelector } from 'react-redux';

import WorkerCard from '../WorkerCard';
import VaccinationForm from './VaccinationsForm';

export default function VaccinationsCard(props) {
   const cardName = 'vaccinations';
   const cardTitle = 'Vaccinations';

   const worker = useSelector(state => state.workers.selected);
   const vaccinations = worker ? worker[cardName] : [];

   const VaccinationItem = ({ item }) => {
      return (
         <div>
            <h5>{item.name}</h5>
            <p>{item.dateTaken}</p>
         </div>
      )
   }

   return (
      <WorkerCard
         title={cardTitle}
         cardName={cardName}
         dataArray={vaccinations}
         ItemInfo={VaccinationItem}
         Form={VaccinationForm}
      />
   )
}
