import React  from 'react';
import { useSelector } from 'react-redux';

import { Row, Col } from 'reactstrap';
import JobCards from './JobCards';

import { Link, NavLink, Switch, Route, Redirect } from 'react-router-dom';
import { LoadingOrError } from 'components';
import fsObjectToArray from "../../../helpers/fsObjectToArray";
import { JOB_STATUSES } from "../jobs.constants";

export default function JobsInfo() {
   const loader = useSelector(state => state.jobs.loader);
   const error = useSelector(state => state.jobs.error);

   const jobs = useSelector(state => state.firestore.data.provider_jobs);

   const notPublishedJobs = fsObjectToArray(jobs).filter(job => job.status === JOB_STATUSES.NOT_PUBLISHED);
   const activeJobs = fsObjectToArray(jobs).filter(job => job.status === JOB_STATUSES.ACTIVE);
   const inProgressJobs = fsObjectToArray(jobs).filter(job => job.status === JOB_STATUSES.IN_PROGRESS);
   const doneJobs = fsObjectToArray(jobs).filter(job => job.status === JOB_STATUSES.DONE);
   const canceledJobs = fsObjectToArray(jobs).filter(job => job.status === JOB_STATUSES.CANCELED);

   return (
      <LoadingOrError loading={ loader } error={ error } className='mt-3'>
         <Row className="mb-3">
            <Col>
               <NavLink className="btn btn-default btn-md btn-fill btn-round" activeClassName="btn-success" to='/jobs/notPublished'> Draft
                  ({ notPublishedJobs.length })</NavLink>
               <NavLink className="btn btn-default btn-md btn-fill btn-round" activeClassName="btn-success"  to='/jobs/active'> Active
                  ({ activeJobs.length })</NavLink>
               <NavLink className="btn btn-default btn-md btn-fill btn-round" activeClassName="btn-success"  to='/jobs/inProgress'> In progress
                  ({ inProgressJobs.length })</NavLink>
               <NavLink className="btn btn-default btn-md btn-fill btn-round" activeClassName="btn-success"  to='/jobs/done'> Done
                  ({ doneJobs.length })</NavLink>
               <NavLink className="btn btn-default btn-md btn-fill btn-round" activeClassName="btn-success"  to='/jobs/canceled'> canceled
                  ({ canceledJobs.length })</NavLink>
               <Link className="btn btn-primary btn-md btn-fill btn-round pull-right" to={ `/job/new` }>Add new job</Link>
            </Col>
         </Row>
         <br />
         <Switch>
            <Redirect exact from="/jobs" to="/jobs/notPublished" />
            <Route path="/jobs/notPublished" exact>
               <JobCards jobs={ notPublishedJobs }/>
            </Route>
            <Route path="/jobs/active" exact>
               <JobCards jobs={ activeJobs }/>
            </Route>
            <Route path="/jobs/inProgress" exact>
               <JobCards jobs={ inProgressJobs }/>
            </Route>
            <Route path="/jobs/done" exact>
               <JobCards jobs={ doneJobs }/>
            </Route>
            <Route path="/jobs/canceled" exact>
               <JobCards jobs={ canceledJobs }/>
            </Route>
         </Switch>
      </LoadingOrError>
   );
}

