import React  from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { DefaultLength, ComposeValidators, Required } from "validators";
import { useSelector } from "react-redux";

import { FormInput } from "components/form";
import { LoadingOrError } from "components";
import FieldSelect from "components/form/FormSelect";
import { Form } from "react-final-form";
import { Pre } from "components/Pre";

function ProviderForm({ onSubmit, initialValues }) {
   const loading = useSelector((state) => state.providers.loader);
   const error = useSelector((state) => state.providers.error);
   const formLoader = useSelector((state) => state.providers.formLoader);

   const users = useSelector(state => state.users.items);

   // useEffect(() => {
   //    if (!users.length) {
   //       dispatch(fetchUsers())
   //    }
   // }, [dispatch, users]);

   // useEffect(() => {
   //    if (changed) {
   //       history.push("/providers");
   //    }
   // }, [changed, history]);

   const usersForProviders = [];
   users &&
   users.forEach((user) => {
      if (user.user_type === "provider") {
         usersForProviders.push({ value: user._id, label: `${ user.email } (${ user.uid })` });
      }
   });

   return (
      <LoadingOrError loading={ loading } error={ error }>
         <Form onSubmit={ onSubmit }
               initialValues={initialValues}
               render={ ({ handleSubmit, values }) => (
                  <form onSubmit={ handleSubmit }>
                     <Card>
                        <CardBody>
                           <Row>
                              <Col md={ 12 }>
                                 { !users && <FormInput
                                    name="user_id"
                                    label="FirebaseID"
                                    type="text"
                                    disabled
                                    placeholder="FirebaseID"
                                 /> }
                              </Col>
                              <Col md={ 6 }>
                                 <FormInput
                                    name="name"
                                    type="text"
                                    label="Name"
                                    validate={ ComposeValidators(Required, DefaultLength) }
                                    disabled={ formLoader }
                                 />
                              </Col>
                              <Col md={ 6 }>
                                 <FormInput
                                    name="state"
                                    type="text"
                                    label="State"
                                    validate={ ComposeValidators(Required, DefaultLength) }
                                    disabled={ formLoader }
                                 />
                              </Col>
                              <Col md={ 6 }>
                                 <FormInput
                                    name="city"
                                    type="text"
                                    label="City"
                                    validate={ ComposeValidators(Required, DefaultLength) }
                                    disabled={ formLoader }
                                 />
                              </Col>
                              <Col md={ 6 }>
                                 <FormInput
                                    name="address"
                                    type="text"
                                    label="Address"
                                    validate={ ComposeValidators(Required, DefaultLength) }
                                    disabled={ formLoader }
                                 />
                              </Col>
                              {
                                 usersForProviders &&
                                 <Col md={ 6 }>
                                    <FieldSelect
                                       name="user_id"
                                       label="Provider Admin"
                                       options={ usersForProviders }
                                       // validate={ ComposeValidators(SelectRequired) }
                                    />
                                 </Col>
                              }
                           </Row>
                        </CardBody>
                     </Card>
                     <Link className="btn btn-default btn-fill btn-wd" to="/providers">
                        Cancel
                     </Link>
                     <Button type="submit" className="btn-wd pull-right" color="primary" disabled={ formLoader }>
                        { " " }
                        { formLoader ? "Saving..." : "Submit" }
                     </Button>
                     <Pre object={ values }/>
                     <Pre object={ users }/>
                  </form>
               ) }/>
      </LoadingOrError>
   );
}

export default ProviderForm;
