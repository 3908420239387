import React from "react";
import { useParams } from "react-router-dom";

import UserPageEdit from '../components/UserPageEdit';
import UserPageNew from '../components/UserPageNew';

function UserPage() {
  let { id } = useParams();

  if (id) {
    return <UserPageEdit />
  }

  return <UserPageNew />
}

export default UserPage;
