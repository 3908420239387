import * as providersTypes from "./providers.types";

const INIT_STATE = {
    items: [],
    loader: false,
    error: null,
    selected: null,
    changed: false,
    formLoader: false,
}

export default function providerReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case providersTypes.FETCH_ALL_PROVIDERS:
            return {
                ...state,
                loader: true,
                error: null,
                selected: null,
                changed: false,
            };
        case providersTypes.FETCH_ALL_PROVIDERS_SUCCESS:
            return {
                ...state,
                items: action.providers,
                loader: false
            };
        case providersTypes.FETCH_ALL_PROVIDERS_ERROR:
            return {
                ...state,
                loader: false,
                error: action.error,
            };
        case providersTypes.SELECT_PROVIDER:
            return {
                ...state,
                loader: true,
            };
        case providersTypes.SELECT_PROVIDER_SUCCESS:
            return {
                ...state,
                selected: action.provider,
                loader: false,
            };
        case providersTypes.SELECT_PROVIDER_ERROR:
            return {
                ...state,
                error: action.error,
                loader: false,
            };
        case providersTypes.ADD_PROVIDER:
            return {
                ...state,
                formLoader: true,
            };
        case providersTypes.ADD_PROVIDER_SUCCESS:
            return {
                ...state,
                changed: true,
                formLoader: false,
            };
        case providersTypes.ADD_PROVIDER_ERROR:
            return {
                ...state,
                error: action.error,
                formLoader: false,
            };
        case providersTypes.EDIT_PROVIDER:
            return {
                ...state,
                formLoader: true,
            };
        case providersTypes.EDIT_PROVIDER_SUCCESS:
            return {
                ...state,
                formLoader: false,
                changed: true,
            };
        case providersTypes.EDIT_PROVIDER_ERROR:
            return {
                ...state,
                error: action.error,
                formLoader: false,
            };
        default:
            return state;
    }
} 
