export const FETCH_ALL_PROVIDERS = 'FETCH_ALL_PROVIDERS';
export const FETCH_ALL_PROVIDERS_SUCCESS = 'FETCH_ALL_PROVIDERS_SUCCESS';
export const FETCH_ALL_PROVIDERS_ERROR = 'FETCH_ALL_PROVIDERS_ERROR';
export const SELECT_PROVIDER = 'SELECT_PROVIDER';
export const SELECT_PROVIDER_SUCCESS = 'SELECT_PROVIDER_SUCCESS';
export const SELECT_PROVIDER_ERROR = 'SELECT_PROVIDER_ERROR';
export const ADD_PROVIDER = 'ADD_PROVIDER';
export const ADD_PROVIDER_SUCCESS = 'ADD_PROVIDER_SUCCESS';
export const ADD_PROVIDER_ERROR = 'ADD_PROVIDER_ERROR';
export const EDIT_PROVIDER = 'EDIT_PROVIDER';
export const EDIT_PROVIDER_SUCCESS = 'EDIT_PROVIDER_SUCCESS';
export const EDIT_PROVIDER_ERROR = 'EDIT_PROVIDER_ERROR';

