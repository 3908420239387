import { Field } from "react-final-form";
import React from "react";

const Condition = ({ when, is, children }) => (
   <Field name={ when } subscription={ { value: true } }>
      { ({ input: { value } }) => (value === is ? children : null) }
   </Field>
)

export default Condition;
