import axios from "axios.js";
import * as types from "./workers.types";

export const getAllWorkers = () => async (dispatch) => {
   dispatch(getAllStartedWorkers());
   try {
      const response = await axios.get("/workers");
      dispatch(getAllWorkersSuccess(response.data.rows));
   } catch (error) {
      dispatch(getAllWorkersError(error.message));
   }
};

export const addWorker = (item) => async (dispatch) => {
   dispatch(addWorkerStart());
   try {
      const response = await axios.post("/workers/", item);
      dispatch(addWorkerSuccess(response.data));
   } catch (error) {
      dispatch(addWorkerError(error));
   }
};

export const selectWorker = (itemID) => async (dispatch) => {
   dispatch(selectWorkerStart());
   try {
      const response = await axios.get(`/workers/${itemID}`);
      dispatch(selectWorkerSuccess(response.data));
   } catch (error) {
      dispatch(selectWorkerError(error));
   }
};

export const editWorker = (item, id) => async (dispatch) => {
   dispatch(editWorkerStart());
   try {
      const response = await axios.put(`/workers/${id}`, item);
      dispatch(editWorkerSuccess(response.data));
   } catch (error) {
      dispatch(editWorkerError(error));
   }
};

export const addAdditionalWorkerInfo = (worker) => async (dispatch) => {
   dispatch(addAdditionalInfoStart());
   try {
      const response = await axios.put(`/workers/${worker._id}`, worker);
      dispatch(addAdditionalInfoSuccess(response.data));
   } catch (error) {
      dispatch(addAdditionalInfoError(error));
   }
};

const getAllStartedWorkers = () => ({
   type: types.FETCH_WORKERS,
});

const getAllWorkersSuccess = (items) => ({
   type: types.FETCH_WORKERS_SUCCESS,
   items,
});

const getAllWorkersError = (error) => ({
   type: types.FETCH_WORKERS_ERROR,
   error,
});

const selectWorkerStart = () => ({
   type: types.SELECT_WORKER,
});

const selectWorkerSuccess = (item) => ({
   type: types.SELECT_WORKER_SUCCESS,
   item,
});

const selectWorkerError = (error) => ({
   type: types.ADD_WORKER_ERROR,
   error,
});

const addWorkerStart = () => ({
   type: types.ADD_WORKER,
});

const addWorkerSuccess = (item) => ({
   type: types.ADD_WORKER_SUCCESS,
   item,
});

const addWorkerError = (error) => ({
   type: types.ADD_WORKER_ERROR,
   error,
});

const editWorkerStart = () => ({
   type: types.EDIT_WORKER,
});

const editWorkerSuccess = (item) => ({
   type: types.EDIT_WORKER_SUCCESS,
   item,
});

const editWorkerError = (error) => ({
   type: types.EDIT_WORKER_ERROR,
   error,
});

const addAdditionalInfoStart = () => ({
   type: types.ADD_ADDITIONAL_WORKER_INFO,
});

const addAdditionalInfoSuccess = (worker) => ({
   type: types.ADD_ADDITIONAL_WORKER_INFO_SUCCESS,
   worker,
});

const addAdditionalInfoError = (error) => ({
   type: types.ADD_ADDITIONAL_WORKER_INFO_ERROR,
   error,
});
