import React from "react";
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from "reactstrap";
import { Doughnut } from "react-chartjs-2";

export function BottomStatCard({ title, subTitle, doughnutData, legend, footerTitle, footerIcon }) {
   return (
      <Card>
         <CardHeader>
            <CardTitle>{ title }</CardTitle>
            <p className="card-category">{ subTitle }</p>
         </CardHeader>
         <CardBody>
            <Doughnut
               data={ doughnutData.data }
               options={ doughnutData.options }
               className="ct-chart ct-perfect-fourth"
               height={ 300 }
               width={ 456 }
            />
         </CardBody>
         <CardFooter>
            <div className="legend">
               <i className="fa fa-circle text-info"/>
               { legend }
            </div>
            <hr/>
            <div className="stats">
               <i className={ `fa ${ footerIcon }` }/>
               { footerTitle }
            </div>
         </CardFooter>
      </Card>
   );
}
