import React from 'react';

const Calendar = () => {
   return (
      <div>
         Calendar
      </div>
   );
};

export default Calendar;
