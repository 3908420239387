import React from "react";

import { BottomStatCards } from "../components/BottomStatCards";
import { ChartStatCards } from "../components/ChartStatCards";
import { TopStatCards } from "../components/TopStatCards"
// import { SalesByStates } from "../components/SalesByStates"

function Dashboard() {
   return (
      <>
         <TopStatCards />
         <ChartStatCards />
         {/*<SalesByStates />*/}
         <BottomStatCards />
      </>
   );
}

export default Dashboard;
