import React, { useContext } from "react";
import { Field } from 'react-final-form'

import {
   FormGroup,
   Input,
} from "reactstrap";

import ReadOnlyContext from 'components/ReadOnlyContext';

const FormInput = (props) => {
   const isReadOnly = useContext(ReadOnlyContext);

   return (
      <Field { ...props }>
         {({ input, meta, label }) => (
            <FormGroup className={`has-label ${meta.touched && meta.error && 'has-danger'}`}>
               { label && <label>{ label }</label> }
               <Input {...input}
                      readOnly={isReadOnly}
                      disabled={props.disabled}
                      className="inputField"/>
               { meta.touched && meta.error && <label className="error">{ meta.error }</label> }
            </FormGroup>
         )}
      </Field>
   );
}

export default FormInput;
