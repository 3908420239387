import React from "react";
import { LoadingOrError } from "components";
import { FormInput } from "components/form";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { Form } from 'react-final-form'

import { ComposeValidators, Email, Required, SelectRequired } from "validators";
import FieldSelect from "components/form/FormSelect";

export const UserTypeOptions = [
   { value: "worker", label: "Worker" },
   { value: "admin", label: "Jeri Admin" },
   { value: "provider", label: "Provider Admin" },
];

const UserPageForm = ({ loading, error, onSubmit, initialValues }) => {
   return (
      <LoadingOrError loading={ loading } error={ error }>
         <Form onSubmit={ onSubmit }
               initialValues={ initialValues }
               render={ ({ handleSubmit, values }) => (
                  <form onSubmit={ handleSubmit }>
                     <Card>
                        <CardBody>
                           <Row>
                              <Col md={ 6 }>
                                 <FormInput name="_id" type="text" label="ID" disabled/>
                              </Col>
                              <Col md={ 6 }>
                                 <FormInput name="uid" label="Firebase ID" disabled/>
                              </Col>
                              <Col md={ 6 }>
                                 <FormInput name="email"
                                            validate={ ComposeValidators(Required, Email) }
                                            label="Email"/>
                              </Col>
                              <Col md={ 6 }>
                                 <FieldSelect
                                    options={ UserTypeOptions }
                                    validate={ ComposeValidators(SelectRequired) }
                                    label="User Type"
                                    name="user_type"
                                    parse={item => item.value}
                                    format={value => value ? UserTypeOptions.find(x => x.value === value) : null}
                                 />
                              </Col>
                              <Col md={ 6 }>
                                 <FormInput name="password"
                                            type="password"
                                            label="Password"/>
                              </Col>
                              <Col md={ 6 }>
                                 <FormInput name="passwordConfirm"
                                            type="password"
                                            label="Password Confirm"/>
                              </Col>
                              <Col md={ 6 }>
                                 <FormInput name="displayName"
                                            label="Display Name"/>
                              </Col>
                           </Row>
                        </CardBody>
                     </Card>
                     <Link className="btn btn-default btn-fill btn-wd" to="/users">
                        Cancel
                     </Link>
                     <Button color="primary" className="btn-wd pull-right" type="submit">
                        Save
                     </Button>
                     <pre>{ JSON.stringify(values, null, 3) }</pre>
                  </form>
               ) }
         >
         </Form>
      </LoadingOrError>
   );
};

export default UserPageForm;
