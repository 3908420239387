import * as types from "./dashboard.types";

const INIT_STATE = {
   activeCount: 0,
   activeAmount: 0,
   activeLoader: false,
   activeError: null,

   notPublishedCount: 0,
   notPublishedAmount: 0,
   notPublishedLoader: false,
   notPublishedError: null,

   doneCount: 0,
   doneAmount: 0,
   doneLoader: false,
   doneError: null,
};

export default function activeReducer(state = INIT_STATE, action) {
   const actionStatus = action.status;
   switch (action.type) {
      case types.FETCH_JOBS_INFO:
         return {
            ...state,
            [actionStatus + "Loader"]: true,
         };
      case types.FETCH_JOBS_INFO_SUCCESS:
         return {
            ...state,
            [actionStatus + "Count"]: action.count,
            [actionStatus + "Amount"]: action.amount,
            [actionStatus + "Loader"]: false,
            [actionStatus + "Error"]: null,
         };
      case types.FETCH_JOBS_INFO_ERROR:
         return {
            ...state,
            [actionStatus + "Loader"]: false,
            [actionStatus + "Error"]: action.error,
         };
      default:
         return state;
   }
}
