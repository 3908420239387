import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { isEmpty } from "react-redux-firebase";
import React from "react";

function PrivateRoute({ component: Component, path, ...props }) {
  const auth = useSelector(state => state.firebase.auth)

  return (
    <Route
      path={path}
      exact={props.exact}
      render={({ location }) =>
        !isEmpty(auth) ? <Component {...props} /> : <Redirect to={{ pathname: "/auth/signin", state: { from: location } }}/>
      }
    />
  );
}

export default PrivateRoute;
