import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";

import TopCardStat from "./TopCardStat";
import { LoadingOrError } from "components";

// import { getCountAndAmountOfJobs } from "../dashboard.actions";

export function TopStatCards() {
   const active = useSelector((state) => state.dashboard.activeCount);
   const notPublished = useSelector((state) => state.dashboard.notPublishedCount);
   const done = useSelector((state) => state.dashboard.doneCount);
   const activeLoader = useSelector((state) => state.dashboard.activeLoader);
   const notPublishedLoader = useSelector((state) => state.dashboard.notPublishedLoader);
   const doneLoader = useSelector((state) => state.dashboard.doneLoader);
   const activeAmount = useSelector((state) => state.dashboard.activeAmount);
   const notPublishedAmount  = useSelector((state) => state.dashboard.notPublishedAmount);
   const doneAmount = useSelector((state) => state.dashboard.doneAmount);
   const activeError = useSelector((state) => state.dashboard.activeError);
   const notPublishedError = useSelector((state) => state.dashboard.notPublishedError);
   const doneError = useSelector((state) => state.dashboard.doneError);

   const dispatch = useDispatch();

   useEffect(() => {
      // dispatch(getCountAndAmountOfJobs("active"));
      // dispatch(getCountAndAmountOfJobs("notPublished"));
      // dispatch(getCountAndAmountOfJobs("done"));
   }, [dispatch]);

   return (
      <Row>
          <Col lg="4" md="6" sm="6">
            <LoadingOrError loading={notPublishedLoader} error={notPublishedError}>
               <TopCardStat
                  title={`New Jobs ( ${notPublished} )`}
                  description="Last day"
                  icon="nc-globe text-warning"
                  iconSmall="fa-refresh"
                  value={`$ ${notPublishedAmount}`}
               />
            </LoadingOrError>
         </Col>

         <Col lg="4" md="6" sm="6">
            <LoadingOrError loading={activeLoader} error={activeError}>
               <TopCardStat
                  title={`Active Jobs ( ${active} )`}
                  description="Update Now"
                  icon="nc-globe text-warning"
                  iconSmall="fa-refresh"
                  value={`$ ${activeAmount}`}
               />
            </LoadingOrError>
         </Col>

         <Col lg="4" md="6" sm="6">
            <LoadingOrError loading={doneLoader} error={doneError}>
               <TopCardStat
                  title={`Done Jobs ( ${done} )`}
                  description="In the last hour"
                  icon="nc-globe text-warning"
                  iconSmall="fa-refresh"
                  value={`$ ${doneAmount}`}
               />
            </LoadingOrError>
         </Col>
      </Row>
   );
}
