import * as types from './jobs.types';
import { JOB_STATUSES } from 'modules/jobs/jobs.constants';

const INIT_STATE = {
   allJobs: [],
   active: [],
   notPublished: [],
   inProgress: [],
   done: [],
   canceled: [],
   selected: null,
   loader: false,
   error: null,
   jobLoader: false,
   jobError: false,
   changed: false,
}

export default function jobsReducer(state = INIT_STATE, action) {
   switch (action.type) {
      case types.FETCH_ALL_JOBS_FOR_PROVIDER:
         return {
            ...state,
            loader: true,
            changed: false,
            selected: null,
         }
      case types.FETCH_ALL_JOBS_FOR_PROVIDER_SUCCESS:
         const jobs = [...action.jobs];
         jobs.forEach(job => {
            const shifts = job.shifts.map(shift => ({
               ...shift
            }));
            job.shifts = shifts;
         });
         return {
            ...state,
            loader: false,
            allJobs: [...jobs],
            active: jobs.filter(job => job.status === JOB_STATUSES.ACTIVE),
            notPublished: jobs.filter(job => job.status === JOB_STATUSES.NOT_PUBLISHED),
            inProgress: jobs.filter(job => job.status === JOB_STATUSES.IN_PROGRESS),
            done: jobs.filter(job => job.status === JOB_STATUSES.DONE),
            canceled: jobs.filter(job => job.status === JOB_STATUSES.CANCELED),
         }
      case types.FETCH_ALL_JOBS_FOR_PROVIDER_ERROR:
         return {
            ...state,
            loader: false,
            error: action.error,
         }
      case types.UPDATE_JOB_STATUS:
         return {
            ...state,
            jobLoader: true,
            jobError: null,
         }
      case types.UPDATE_JOB_STATUS_SUCCESS:
         const newJobs = state.allJobs.map(job => job._id === action.job.id ? { ...job, status: action.job.updatedStatus } : job)

         return {
            ...state,
            jobLoader: false,
            allJobs: newJobs,
            active: newJobs.filter(job => job.status ===  JOB_STATUSES.ACTIVE),
            notPublished: newJobs.filter(job => job.status ===  JOB_STATUSES.NOT_PUBLISHED),
            inProgress: newJobs.filter(job => job.status ===  JOB_STATUSES.IN_PROGRESS),
            done: newJobs.filter(job => job.status ===  JOB_STATUSES.DONE),
            canceled: newJobs.filter(job => job.status ===  JOB_STATUSES.CANCELED),
         }
      case types.UPDATE_JOB_STATUS_ERROR:
         return {
            ...state,
            jobLoader: false,
            jobError: action.error,
         }
      case types.ADD_JOB:
         return {
            ...state,
            jobLoader: true,
            error: null,
            changed: false
         }
      case types.ADD_JOB_SUCCESS:
         return {
            ...state,
            jobLoader: false,
            changed: true,
            error: null,
         }
      case types.ADD_JOB_ERROR:
         return {
            ...state,
            jobLoader: false,
            error: action.error,
         }
      case types.EDIT_JOB:
         return {
            ...state,
            jobLoader: true,
            error: null,
            changed: false
         }
      case types.EDIT_JOB_SUCCESS:
         return {
            ...state,
            jobLoader: false,
            changed: true,
            error: null,
         }
      case types.EDIT_JOB_ERROR:
         return {
            ...state,
            jobLoader: false,
            error: action.error,
         }
      case types.SELECT_JOB:
         return {
            ...state,
            loader: true,
         }
      case types.SELECT_JOB_SUCCESS:
         return {
            ...state,
            loader: false,
            selected: { ...action.job },
            error: null,
         }
      case types.SELECT_JOB_ERROR:
         return {
            ...state,
            loader: false,
            error: action.error,
         }
      default:
         return state;
   }
}
