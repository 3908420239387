import * as claimsTypes from './auth.types';

const initialState = {
  loading: false,
  isLoaded: false,
  data: {},
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case claimsTypes.CLAIMS_STARTED:
      return {
        ...state,
        loading: true
      };
    case claimsTypes.CLAIMS_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoaded: true,
        error: null,
        data: action.payload
      };
    case claimsTypes.CLAIMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}
