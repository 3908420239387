import React from "react";
import { useParams } from "react-router-dom";

import WorkerPageNew from "../components/WorkerPageNew";
import WorkerPageEdit from "../components/WorkerPageEdit";

export default function WorkerPage() {
   const { id } = useParams();

   return id ? <WorkerPageEdit /> : <WorkerPageNew />;
}
