// import React from 'react';
//
// const SignUp = () => {
//    return (
//       <div>
//          SignUp
//       </div>
//    );
// };
//
// export default SignUp;

import React from "react";

import {
   Button,
   Card,
   CardHeader,
   CardBody,
   CardFooter,
   CardTitle,
   Label,
   FormGroup,
   Form,
   Input,
   InputGroupAddon,
   InputGroupText,
   InputGroup,
   Container,
   Row,
   Col
} from "reactstrap";

class SignUp extends React.Component {
   componentDidMount() {
      document.body.classList.toggle("register-page");
   }

   componentWillUnmount() {
      document.body.classList.toggle("register-page");
   }

   render() {
      return (
         <div className="register-page">
            <Container>
               <Row>
                  <Col className="ml-auto" lg="5" md="5">
                     <div className="info-area info-horizontal mt-5">
                        <div className="icon icon-primary">
                           <i className="nc-icon nc-tv-2"/>
                        </div>
                        <div className="description">
                           <h5 className="info-title">Marketing 1</h5>
                           <p className="description">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud...
                           </p>
                        </div>
                     </div>
                     <div className="info-area info-horizontal">
                        <div className="icon icon-primary">
                           <i className="nc-icon nc-html5"/>
                        </div>
                        <div className="description">
                           <h5 className="info-title">Marketing 2</h5>
                           <p className="description">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                           </p>
                        </div>
                     </div>
                     <div className="info-area info-horizontal">
                        <div className="icon icon-info">
                           <i className="nc-icon nc-atom"/>
                        </div>
                        <div className="description">
                           <h5 className="info-title">Marketing 3</h5>
                           <p className="description">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua.
                           </p>
                        </div>
                     </div>
                  </Col>
                  <Col className="mr-auto" lg="6" md="6">
                     <Card className="card-signup text-center">
                        <CardHeader>
                           <CardTitle tag="h4">Provider Sign Up</CardTitle>
                        </CardHeader>
                        <CardBody>
                           <Form action="" className="form" method="">
                              <InputGroup>
                                 <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                       <i className="nc-icon nc-single-02"/>
                                    </InputGroupText>
                                 </InputGroupAddon>
                                 <Input placeholder="Provider Name" type="text"/>
                              </InputGroup>
                              <InputGroup>
                                 <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                       <i className="nc-icon nc-circle-10"/>
                                    </InputGroupText>
                                 </InputGroupAddon>
                                 <Input placeholder="Main Contact Person" type="text"/>
                              </InputGroup>
                              <InputGroup>
                                 <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                       <i className="nc-icon nc-email-85"/>
                                    </InputGroupText>
                                 </InputGroupAddon>
                                 <Input placeholder="Email..." type="email"/>
                              </InputGroup>
                              <InputGroup>
                                 <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                       <i className="nc-icon nc-email-85"/>
                                    </InputGroupText>
                                 </InputGroupAddon>
                                 <Input placeholder="Phone" type="tel"/>
                              </InputGroup>
                              <InputGroup>
                                 <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                       <i className="nc-icon nc-email-85"/>
                                    </InputGroupText>
                                 </InputGroupAddon>
                                 <Input placeholder="Password" type="password"/>
                              </InputGroup>
                              <FormGroup check className="text-left">
                                 <Label check>
                                    <Input defaultChecked type="checkbox"/>
                                    <span className="form-check-sign"/>I agree to the{ " " }
                                    <a href="#pablo" onClick={ e => e.preventDefault() }>
                                       terms and conditions
                                    </a>
                                    .
                                 </Label>
                              </FormGroup>
                           </Form>
                        </CardBody>
                        <CardFooter>
                           <Button
                              className="btn-round"
                              color="info"
                              href="#pablo"
                              onClick={ e => e.preventDefault() }
                           >
                              Get Started
                           </Button>
                        </CardFooter>
                     </Card>
                  </Col>
               </Row>
            </Container>
            <div
               className="full-page-background"
               style={ {
                  backgroundImage: `url(${ require("assets/images/jan-sendereks.jpg") })`
               } }
            />
         </div>
      );
   }
}

export default SignUp;
