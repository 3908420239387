import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import UserPageForm from "modules/users/components/UserPageForm";

import { fetchUser, saveUser } from "modules/users/users.actions";

const UserPageEdit = () => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const loading = useSelector(state => state.users.loader);
  const error = useSelector(state => state.users.error);
  const userItem = useSelector(state => state.users.items.find(u => u._id === id));

  useEffect(() => {
    if (!userItem) {
      dispatch(fetchUser(id))
    }
  }, [dispatch, id, userItem]);

  return (
    <UserPageForm loading={loading}
                  error={error}
                  initialValues={userItem}
                  onSubmit={(user) => dispatch(saveUser({ ...user })) } />
  );
};

export default UserPageEdit;
