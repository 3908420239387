import React from "react";

import { Nav, NavItem, Row, Col } from "reactstrap";
import { NavLink, Switch, Route, useParams } from "react-router-dom";

import EducationCard from "./workerInfoCards/education/EducationCard";
import WorkHistoryCard from "./workerInfoCards/workHistory/WorkHistoryCard";
import ReferencesCard from "./workerInfoCards/references/ReferencesCard";
import SpecialtiesCard from "./workerInfoCards/specialties/SpecialtiesCard";
import LicencesCard from "./workerInfoCards/licences/LicencesCard";
import CertificationsCard from "./workerInfoCards/certifications/CertificationsCard";
import VaccinationsCard from "./workerInfoCards/vaccinations/VaccinationsCard";

export default function WorkerAdditionalInfo() {
   const params = useParams();
   const { id } = params;

   const TabItemLink = ({ title, path }) => (
      <NavItem>
         <NavLink className={ "nav-link" } to={ `/worker/${ id }/${ path }` }>
            { title }
         </NavLink>
      </NavItem>
   )

   const tabs = [
      { title: 'Education', path: 'education', component: EducationCard },
      { title: 'Work History', path: 'workHistory', component: WorkHistoryCard },
      { title: 'References', path: 'references', component: ReferencesCard },
      { title: 'Specialties', path: 'specialties', component: SpecialtiesCard },
      { title: 'Licences', path: 'licences', component: LicencesCard },
      { title: 'Certifications', path: 'certifications', component: CertificationsCard },
      { title: 'Vaccinations', path: 'vaccinations', component: VaccinationsCard },
   ];

   return (
      <div className="mt-3">
         <h3>Additional info</h3>
         <Row>
            <Col xs={ 2 }>
               <Nav pills vertical>
                  {
                     tabs.map(({ title, path }) => <TabItemLink key={ title }
                                                                title={ title }
                                                                path={ path }/>)
                  }
               </Nav>
            </Col>
            <Col xs={ 10 }>
               <Switch>
                  {
                     tabs.map(({ path, component }) => <Route key={ path }
                                                              path={ `/worker/${ id }/${ path }` }
                                                              component={ component }/>)
                  }
               </Switch>
            </Col>
         </Row>
      </div>
   );
}
