import React from 'react';
import { useSelector } from 'react-redux';

import WorkerCard from '../WorkerCard';
import CertificationsForm from './CertificationsForm';

export default function CertificationsCard(props) {
   const cardName = 'certifications';
   const cardTitle = 'Certifications';

   const worker = useSelector(state => state.workers.selected);
   const certifications = worker ? worker[cardName] : [];

   const CertifcateItem = ({ item }) => {
      return (
         <div>
            <h5>{item.name}</h5>
            <p>{item.obtained}</p>
            <p>{item.validFrom} - {item.validTo}</p>
            <p>{item.extra}</p>
         </div>
      )
   }

   return (
      <WorkerCard
         title={cardTitle}
         cardName={cardName}
         dataArray={certifications}
         ItemInfo={CertifcateItem}
         Form={CertificationsForm}
      />
   )
}
