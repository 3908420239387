import React from "react";
import UserPageForm from "modules/users/components/UserPageForm";
import { saveUser } from "modules/users/users.actions";
import { useDispatch } from "react-redux";

const UserPageNew = () => {
  const dispatch = useDispatch();

  return (
    <UserPageForm loading={false}
                  error={null}
                  initialValues={{}}
                  onSubmit={(user) => dispatch(saveUser({ ...user, user_type: user.user_type.value })) } />
  );
};

export default UserPageNew;
