
export const PAYMENT_TYPE = 'hourly'; // byShift

export const SHIFT_START_AT_HOURS = 8;
export const SHIFT_END_AT_HOURS = 9;
export const SHIFT_SIZE_MINUTES = 60;

export const PERCENTAGE_FEE = 33;
export const HOURLY_RATE = 25;
export const HOURLY_WORKERS_PART = 20;
export const HOURLY_FEE = +(((PERCENTAGE_FEE / 100) * HOURLY_RATE).toFixed(2));

export const JOB_STATUSES = {
   NOT_PUBLISHED: 'notPublished',
   ACTIVE: 'active',
   IN_PROGRESS: 'inProgress',
   DONE: 'done',
   CANCELED: 'canceled',
}

export const JobTypes = [
   { value: "cma", label: "Certified Medication Aide (CMA)" },
   { value: "cna", label: "Certified Nurse Aide (CNA)" },
   { value: "hha", label: "Home Health Aide (HHA)" },
   { value: "lpn", label: "Licensed Practical Nurse (LPN)" },
   { value: "rn", label: "Registered Nurse (RN)" },
   { value: "np", label: "Nurse Practitioner (NP)" },
   { value: "ots", label: "Occupational Therapists (OTs)" },
   { value: "pt", label: "Physical Therapist (PT)" },
   { value: "sw", label: "Social Worker (SW)" },
];
