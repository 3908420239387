import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import WorkerForm from "./WorkerPageForm";

import { addWorker } from "../workers.action";
import { fetchUsers } from "../../users/users.actions";

export default function Worker() {
   const dispatch = useDispatch();

   const users = useSelector(state => state.users.items);

   useEffect(() => {
      if (!users.length) {
         dispatch(fetchUsers())
      }
   }, [dispatch, users]);

   const onSubmit = (data) => {
      const worker = {
         ...data,
         user_id: data.user_id ? data.user_id.value : undefined
      }
      dispatch(addWorker(worker));
   };

   return (
      <WorkerForm onSubmit={onSubmit} users={users}/>
   );
}
