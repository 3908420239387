import React from "react";

import ProviderForm from "./ProviderForm";
import { AddProvider } from "../providers.actions";

export default function Provider() {
   return (
      <ProviderForm onSubmit={ (provider) => AddProvider(provider) }/>
   )
}
