import React from "react";
import { Card, CardBody, CardFooter, CardTitle, Col, Row } from "reactstrap";

const TopCardStat = ({ title, description, value, icon, iconSmall }) => {
   return (
      <Card className="card-stats">
         <CardBody>
            <Row>
               <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                     <i className={ `nc-icon ${ icon }` }/>
                  </div>
               </Col>
               <Col md="8" xs="7">
                  <div className="numbers">
                     <p className="card-category">{ title }</p>
                     <CardTitle tag="p">{ value }</CardTitle>
                     <p/>
                  </div>
               </Col>
            </Row>
         </CardBody>
         <CardFooter>
            <hr/>
            {/* <div className="stats">
               <i className={ `fa ${ iconSmall }` }/>
               { description }
            </div> */}
         </CardFooter>
      </Card>
   );
}

export default TopCardStat;
