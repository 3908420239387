import * as types from "./workers.types";

const INIT_STATE = {
   items: [],
   loader: false,
   error: null,
   selected: null,
   changed: false,
   formLoader: false,
   dataLoader: false,
   newWorker: null,
};

export default function workersReducer(state = INIT_STATE, action) {
   switch (action.type) {
      case types.FETCH_WORKERS:
         return {
            ...state,
            loader: true,
            error: null,
            selected: null,
            newWorker: null,
            changed: false,
         };
      case types.FETCH_WORKERS_SUCCESS:
         return {
            ...state,
            items: action.items,
            loader: false,
         };
      case types.FETCH_WORKERS_ERROR:
         return {
            ...state,
            loader: false,
            error: action.error,
         };
      case types.SELECT_WORKER:
         return {
            ...state,
            loader: true,
         };
      case types.SELECT_WORKER_SUCCESS:
         return {
            ...state,
            selected: action.item,
            loader: false,
         };
      case types.SELECT_WORKER_ERROR:
         return {
            ...state,
            error: action.error,
            loader: false,
         };
      case types.ADD_WORKER:
         return {
            ...state,
            formLoader: true,
         };
      case types.ADD_WORKER_SUCCESS:
         return {
            ...state,
            changed: true,
            newWorker: action.item._id,
            formLoader: false,
         };
      case types.ADD_WORKER_ERROR:
         return {
            ...state,
            error: action.error,
            formLoader: false,
         };
      case types.EDIT_WORKER:
         return {
            ...state,
            formLoader: true,
         };
      case types.EDIT_WORKER_SUCCESS:
         return {
            ...state,
            changed: true,
            formLoader: false,
         };
      case types.EDIT_WORKER_ERROR:
         return {
            ...state,
            error: action.error,
            formLoader: false,
         };
      case types.ADD_ADDITIONAL_WORKER_INFO:
         return {
            ...state,
            dataLoader: true,
            error: null,
         };
      case types.ADD_ADDITIONAL_WORKER_INFO_SUCCESS:
         return {
            ...state,
            selected: { ...action.worker },
            error: null,
            dataLoader: false,
         };
      case types.ADD_ADDITIONAL_WORKER_INFO_ERROR:
         return {
            ...state,
            dataLoader: false,
            error: action.error,
         };
      default:
         return state;
   }
}
