import React from 'react';
import { FormInput } from "components/form"
import { DefaultLength, ComposeValidators, Required } from "validators";

function CertificationsForm() {
   return (
      <>
         <FormInput validate={ ComposeValidators(Required, DefaultLength) } name="name" label="Name" type="text" />
         <FormInput validate={ ComposeValidators(Required, DefaultLength) } name="obtained" label="Obtained" type="text" />
         <FormInput validate={ ComposeValidators(Required, DefaultLength) } name="validFrom" label="ValidFrom" type="text" />
         <FormInput validate={ ComposeValidators(Required, DefaultLength) } name="validTo" label="ValidTo" type="text" />
         <FormInput validate={ ComposeValidators(DefaultLength) } name="extra" label="Extra" type="text" />
      </>
   )
}

export default CertificationsForm;
