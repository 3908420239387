import React from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import ProviderForm from "./ProviderForm";

import {UpdateProvider} from "../providers.actions";

export default function Provider() {
    const {id} = useParams();

    const providerItem = useSelector(state => state.firestore.data.providers && state.firestore.data.providers[id]);

    return (
        <ProviderForm
            initialValues={{DocumentID: id, ...providerItem}}
            onSubmit={(provider) => UpdateProvider(provider)}
            // onSubmit={(provider) => console.log(provider)}
        />
    )
}
