// https://github.com/validatorjs/validator.js
import validator from 'validator';
import moment from 'moment';

const DEFAULT_MIN = 2;
const DEFAULT_MAX = 50;

export const SelectRequired = value => {
   if (value === undefined) {
      return "Required field"
   } else {
      return undefined;
   }
};

export const DateTimeRequired = value => {
   if (value === undefined) {
      return "Required field"
   } else {
      return undefined;
   }
};

export const ComposeValidators = (...validators) => value =>
   validators.reduce((error, validator) => error || validator(value), undefined)

export const Required = value => (value ? undefined : 'Required');

export const Empty = value => !validator.isEmpty(value || '') ? undefined : 'Value can\'t be empty';

export const Email = value => validator.isEmail(value) ? undefined : 'Invalid email address';

export const Date = value => validator.isISO8601(value || '') ? undefined : 'Invalid date';

export const NotNegative = value => +value >= 0 ? undefined : 'Value can not be less than 0';

export const Positive = value => +value > 0 ? undefined : 'Value must be greater than 0';

export const ShiftLength = value => validator.isLength(value || '', {min: 0, max: 5 } )  ? undefined : 'Value can not be longer than 10,000';

export const DefaultLength = value => {
   if (validator.isEmpty(value || ''))
      return undefined;
   else if (validator.isLength(value || '', { min: DEFAULT_MIN, max: DEFAULT_MAX })) {
      return undefined;
   } else {
      return `Value must be between ${DEFAULT_MIN} and ${DEFAULT_MAX} characters long`;
   }
}

export const PhoneNumber = value => validator.isMobilePhone(value || '') ? undefined : 'Value must be a valid phone number';


export const validateJob = values => {
   const errors = []
   if (!values.shifts || !values.shifts.length) {
      errors.shifts = { _error: 'At least one shift must be entered' };
   } else {
      const shiftsArrayErrors = []
      values.shifts.forEach((shift, index) => {
         const shiftErrors = {};
         if (shift.shiftEnd && shift.shiftStart) {
            if (moment(shift.shiftEnd).isBefore(moment(shift.shiftStart))) {
               shiftErrors.shiftEnd = 'Shift end must be after shift start!';
               shiftErrors.shiftEnd = 'Shift end must be after shift start!';
               shiftsArrayErrors[index] = shiftErrors;
            }
            if (moment(shift.shiftEnd).diff(moment(shift.shiftStart), 'h', true) > 24) {
               shiftErrors.shiftStart = 'Shift can not be longer than 24 hours';
               shiftErrors.shiftEnd = 'Shift can not be longer than 24 hours';
               shiftsArrayErrors[index] = shiftErrors;
            }
            if (shift.billableHours && shift.billableHours > moment(shift.shiftEnd).diff(moment(shift.shiftStart), 'h', true)) {
               shiftErrors.billableHours = 'Shift duration must be longer than billable hours';
               shiftsArrayErrors[index] = shiftErrors;
            }
         }
      });
      if (shiftsArrayErrors.length) {
         errors.shifts = shiftsArrayErrors;
      }
   }
   return errors;
}
