import React from 'react';

import JobForm from '../components/JobForm';

import { AddJob } from '../jobs.actions';
import { add, startOfDay } from "date-fns";

import {
   SHIFT_START_AT_HOURS,
   SHIFT_END_AT_HOURS,
   SHIFT_SIZE_MINUTES, JOB_STATUSES
} from 'modules/jobs/jobs.constants'

export default function JobPage() {
   const initShift = {
      start: add(startOfDay(new Date()), { hours: SHIFT_START_AT_HOURS }),
      end: add(startOfDay(new Date()), { hours: SHIFT_END_AT_HOURS }),
      minutes: SHIFT_SIZE_MINUTES
   }

   return (
      <JobForm onSubmit={(job) => AddJob(job) }
               job={ { shifts: [{ ...initShift }], status: JOB_STATUSES.NOT_PUBLISHED } }/>
   )
}
