import React from "react";
import { useParams } from "react-router-dom";

import JobPageNew from "../components/JobPageNew";
import JobPageEdit from "../components/JobPageEdit";

export default function JobPage() {
   const { id } = useParams();

   return id ? <JobPageEdit /> : <JobPageNew />

}
