import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router";
import { Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from 'redux-firestore'

import "bootstrap/dist/css/bootstrap.css";
import "assets/css/paper-dashboard.css"
import 'react-perfect-scrollbar/dist/css/styles.css';

import { AuthLayout, AdminLayout } from 'layouts';
import AuthIsLoaded from 'components/AuthIsLoaded';
import PrivateRoute from 'components/PrivateRoute';

import configureStore from './configureStore'

import firebase from './firebase';

export const store = configureStore();

const history = createBrowserHistory();

const rrfProps = {
   firebase,
   config: { },
   dispatch: store.dispatch,
   createFirestoreInstance
}

ReactDOM.render(
   <Provider store={ store }>
      <ReactReduxFirebaseProvider { ...rrfProps }>

         <Router history={ history }>
            <AuthIsLoaded>
               <Switch>
                  <Route path="/auth/signout" render={ () => {
                     firebase.auth().signOut();
                     return <Redirect to="/auth/signin"/>
                  } }/>
                  <Route path="/auth" component={ AuthLayout }/>
                  <PrivateRoute path="/" component={ AdminLayout }/>
                  <Redirect to="/"/>
               </Switch>
            </AuthIsLoaded>
         </Router>

      </ReactReduxFirebaseProvider>
   </Provider>,
   document.getElementById('root')
);
