import React from 'react';

const PasswordReset = () => {
   return (
      <div>
         Password Reset
      </div>
   );
};

export default PasswordReset;
