import React from 'react';
import { useSelector } from 'react-redux';

import WorkerCard from '../WorkerCard';
import ReferenceForm from "./ReferenceForm"

export default function ReferencesCard(props) {
   const cardName = 'references';
   const cardTitle = 'References';

   const worker = useSelector(state => state.workers.selected);
   const references = worker ? worker[cardName] : [];

   const ReferenceItem = ({ item }) => {
      return (
         <div>
            <h5>{item.position}</h5>
            <p>{item.company.toUpperCase()}</p>
            <p>{item.started} - {item.ended || 'Present'}</p>
            <p>{item.extra}</p>
         </div>
      )
   }

   return (
      <WorkerCard
         title={cardTitle}
         cardName={cardName}
         dataArray={references}
         ItemInfo={ReferenceItem}
         Form={ReferenceForm}
      />
   )
}
