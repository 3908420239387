import React, { useState } from 'react';
import { Button, Card, CardBody, Row, Col } from 'reactstrap';
import ModalForm from 'components/BasicModalForm';
import { useSelector, useDispatch } from 'react-redux';

import CardItem from './CardItem';

import { addAdditionalWorkerInfo } from '../../workers.action';

export default function WorkerCard({ title, dataArray, cardName, ItemInfo, Form }) {
   const dispatch = useDispatch();

   const worker = useSelector(state => state.workers.selected);

   const [modalOpen, setModalOpen] = useState(false);
   const [selected, setSelected] = useState(undefined);
   const [formData, setFormData] = useState({});

   function handleHideModal() {
      setModalOpen(false);
   }

   function handleShowModal(index) {
      if (index >= 0) {
         setSelected(index);
         setFormData(worker[cardName][index])
      }
      else {
         setSelected(undefined);
         setFormData({})
      }
      setModalOpen(true);
   }

   function onSubmit(formData) {
      let newInfo = [...worker[cardName]];
      if (selected >= 0) {
         newInfo[selected] = formData;
      } else {
         newInfo.push(formData);
      }
      const newWorker = { ...worker, [cardName]: newInfo };
      dispatch(addAdditionalWorkerInfo(newWorker));

      handleHideModal();
   }

   const ItemView = ({ item }) => {
      return <ItemInfo item={item} />
   }

   return (
      <Card>
         <CardBody>
            <Row className='pl-1'>
               <Col md={11}>
                  <h3>{title}</h3>
               </Col>
               <Col md={1}>
                  <Button
                     onClick={() => handleShowModal()}
                     className="btn-round btn-icon">
                     <i className="nc-icon nc-simple-add"></i>
                  </Button>
               </Col>
            </Row>
            {
               dataArray.map((item, index) =>
                  <div key={`${cardName}_${index}`}>
                     <CardItem name={cardName} index={index} onClick={handleShowModal}>
                        <ItemView item={item} />
                     </CardItem>
                     {index + 1 !== dataArray.length && <hr />}
                  </div>
               )
            }
         </CardBody>
         {modalOpen &&
            <ModalForm
               isOpen={modalOpen}
               onModalClose={handleHideModal}
               title={title}
               onSubmit={onSubmit}
               initialValues={formData}
            >
               <Form />
            </ModalForm>}
      </Card>
   )
}
