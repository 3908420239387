import React from 'react';
import JobsInfo from '../components/JobsInfo';

const Jobs = () => {
   return (
      <JobsInfo/>
   );
};

export default Jobs;
