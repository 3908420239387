import { getFirebase } from "react-redux-firebase";

export function AddProvider(provider) {
   const { firestore } = getFirebase();

   return firestore()
      .collection('providers')
      .add({ ...provider, deleted: false });
}

export function UpdateProvider({ DocumentID, ...provider }) {
   const { firestore } = getFirebase();

   return firestore()
      .collection('providers')
      .doc(DocumentID)
      .update(provider)
      .then(function() {
         console.log("Document successfully updated!");
      })
      .catch(function(error) {
         console.error("Error writing document: ", error);
      });
}
