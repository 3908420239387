import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { Form } from 'react-final-form'

import { ComposeValidators, Email, PhoneNumber, Required } from "validators";

import { FormInput } from "components/form";
import FieldSelect from 'components/form/FormSelect';
import { Pre } from "components/Pre";

function WorkerForm({ onSubmit, users, initialValues = {} }) {
   const history = useHistory();

   const changed = useSelector((state) => state.workers.changed);
   const newWorker = useSelector((state) => state.workers.newWorker);
   const formLoader = useSelector((state) => state.workers.formLoader);

   useEffect(() => {
      if (changed && newWorker) {
         history.push(`/worker/${ newWorker }`);
      } else if (changed) {
         history.push("/workers");
      }
   }, [newWorker, changed, history]);

   const Users = [];
   users &&
   users.forEach((user) => {
      if (user.user_type === "worker") {
         Users.push({ value: user.uid, label: `${ user.email } (${ user.uid })` });
      }
   });

   return (
      <Form onSubmit={ onSubmit }
            initialValues={ initialValues }
            render={ ({ handleSubmit, values }) => (
               <form onSubmit={ handleSubmit }>
                  <Card>
                     <CardBody>
                        <Row className="row">
                           <Col md={ 12 }>
                              { !users && (
                                 <FormInput name="user_id"
                                            label="FirebaseID"
                                            type="text"
                                            disabled
                                            placeholder="FirebaseID"/>
                              ) }
                           </Col>
                           <Col md={ 4 }>
                              <FormInput
                                 name="firstName"
                                 label="First Name"
                                 type="text"
                                 disabled={ formLoader }
                                 validate={ ComposeValidators(Required) }
                              />
                           </Col>
                           <Col md={ 4 }>
                              <FormInput
                                 name="middleName"
                                 label="Middle Name"
                                 type="text"
                                 disabled={ formLoader }
                              />
                           </Col>
                           <Col md={ 4 }>
                              <FormInput
                                 name="lastName"
                                 label="Last Name"
                                 type="text"
                                 disabled={ formLoader }
                                 validate={ ComposeValidators(Required) }
                              />
                           </Col>
                           <Col md={ 6 }>
                              <FormInput
                                 name="phone"
                                 label="Phone"
                                 type="text"
                                 disabled={ formLoader }
                                 validate={ ComposeValidators(Required, PhoneNumber) }
                              />
                           </Col>
                           <Col md={ 6 }>
                              <FormInput
                                 name="email"
                                 label="Email"
                                 type="email"
                                 disabled={ formLoader }
                                 validate={ ComposeValidators(Required, Email) }
                              />
                           </Col>
                           <Col md={ 6 }>
                              <FormInput
                                 name="address"
                                 label="Address"
                                 type="text"
                                 disabled={ formLoader }
                                 validate={ ComposeValidators(Required) }
                              />
                           </Col>
                           { users && <Col md={ 6 }>
                              <FieldSelect
                                 options={ Users }
                                 name="user_id"
                                 label="User ID"
                                 disabled={ formLoader }
                                 //validate={ [SelectRequired] }
                              />
                           </Col> }
                        </Row>
                     </CardBody>
                  </Card>
                  <Link to="/workers" className="btn btn-default btn-fill btn-wd">
                     Cancel
                  </Link>
                  <Button type="submit" color="primary" className="btn-wd pull-right" disabled={ formLoader }>
                     { " " }
                     { formLoader ? "Saving..." : "Submit" }
                  </Button>
                  <Pre object={ values }/>
               </form>
            ) }>
      </Form>
   );
}

export default WorkerForm
