import React from 'react';

const Settings = () => {
   return (
      <div>
         Settings
      </div>
   );
};

export default Settings;
