export const FETCH_ALL_JOBS_FOR_PROVIDER = 'FETCH_ALL_JOBS_FOR_PROVIDER';
export const FETCH_ALL_JOBS_FOR_PROVIDER_SUCCESS = 'FETCH_ALL_JOBS_FOR_PROVIDER_SUCCESS';
export const FETCH_ALL_JOBS_FOR_PROVIDER_ERROR = 'FETCH_ALL_JOBS_FOR_PROVIDER_ERROR';
export const UPDATE_JOB_STATUS = 'UPDATE_JOB_STATUS';
export const UPDATE_JOB_STATUS_SUCCESS = 'UPDATE_JOB_STATUS_SUCCESS';
export const UPDATE_JOB_STATUS_ERROR = 'UPDATE_JOB_STATUS_ERROR';
export const ADD_JOB = 'ADD_JOB';
export const ADD_JOB_SUCCESS = 'ADD_JOB_SUCCESS';
export const ADD_JOB_ERROR = 'ADD_JOB_ERROR';
export const EDIT_JOB = 'EDIT_JOB';
export const EDIT_JOB_SUCCESS = 'EDIT_JOB_SUCCESS';
export const EDIT_JOB_ERROR = 'EDIT_JOB_ERROR';
export const SELECT_JOB = 'SELECT_JOB';
export const SELECT_JOB_SUCCESS = 'SELECT_JOB_SUCCESS';
export const SELECT_JOB_ERROR = 'SELECT_JOB_ERROR';

