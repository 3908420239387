import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
import PerfectScrollbar from 'react-perfect-scrollbar'

import avatar from "assets/images/kaci-baum-2.jpg";
import logo from "assets/images/react-logo.png";

export function SidebarUser() {
   const auth = useSelector(state => state.firebase.auth)
   const claims = useSelector(state => state.claims && state.claims.data);

   const { admin } = claims;

   const [openAvatar, setOpenAvatar] = useState(false);

   return (
      <div className="user">
         <div className="photo">
            <img src={ avatar } alt="Avatar"/>
         </div>
         <div className="info">
            <a
               href="#pablo"
               data-toggle="collapse"
               aria-expanded={ openAvatar }
               onClick={ () => setOpenAvatar(!openAvatar) }
            >
            <span>
               { auth.displayName || auth.email }
               <b className="caret"/>
            </span>
               {
                  admin &&
                  <span
                     style={ { fontSize: '7px', lineHeight: '0px', marginTop: '5px', color: '#9a9a9a' } }>ADMIN</span>
               }
            </a>
            <Collapse isOpen={ openAvatar }>
               <ul className="nav">
                  <li>
                     <NavLink to="/profile" activeClassName="">
                        <span className="sidebar-mini-icon">MP</span>
                        <span className="sidebar-normal">My Profile</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to="/settings" activeClassName="">
                        <span className="sidebar-mini-icon">S</span>
                        <span className="sidebar-normal">Settings</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to="/billing" activeClassName="">
                        <span className="sidebar-mini-icon">B</span>
                        <span className="sidebar-normal">Billing</span>
                     </NavLink>
                  </li>
               </ul>
            </Collapse>
         </div>
      </div>
   )
}

export function SidebarNew({ bgColor, activeColor, routes, location = {} }) {
   const claims = useSelector(state => state.claims.data)
   const { admin, provider } = claims;

   const createLinks = routes => {
      return routes.map((prop, key) => {
         if (prop.noSideMenu) {
            return null;
         }

         if (admin && !prop.onlyAdmin) {
            return null;
         }

         if (provider && prop.onlyAdmin) {
            return null;
         }

         return (
            <li className={ location.pathname.indexOf(prop.layout + prop.path) > -1 ? "active" : "" } key={ key }>
               <NavLink to={ prop.layout + prop.path } activeClassName="">
                  { prop.icon !== undefined ? (
                     <>
                        <i className={ prop.icon }/>
                        <p>{ prop.name }</p>
                     </>
                  ) : (
                     <>
                        <span className="sidebar-mini-icon">{ prop.mini }</span>
                        <span className="sidebar-normal">{ prop.name }</span>
                     </>
                  ) }
               </NavLink>
            </li>
         );
      });
   };

   return (
      <div className="sidebar" data-color={ bgColor } data-active-color={ activeColor }>
         <div className="logo">
            <Link to={ '/' } className="simple-text logo-mini">
               <div className="logo-img">
                  <img src={ logo } alt="react-logo"/>
               </div>
            </Link>
            <Link to={ '/' } className="simple-text logo-normal">Jeri Platform</Link>
         </div>
         <PerfectScrollbar className="sidebar-wrapper" options={ { suppressScrollX: true, suppressScrollY: false } }>
            <SidebarUser/>
            <Nav>{ createLinks(routes) }</Nav>
         </PerfectScrollbar>
      </div>
   )
}
