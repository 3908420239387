import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { LoadingOrError } from "components";
import WorkerForm from "./WorkerPageForm";
import WorkerAdditionalInfo from "./WorkerAdditionalInfo";

import { selectWorker, editWorker } from "../workers.action";

export default function Worker() {
   const dispatch = useDispatch();
   const { id } = useParams();

   const workerItem = useSelector((state) => state.workers.selected);

   useEffect(() => {
      dispatch(selectWorker(id));
   }, [dispatch, id]);

   const onSubmit = (worker) => {
      dispatch(editWorker(worker, id));
   };
   const loading = useSelector((state) => state.workers.loader);
   const error = useSelector((state) => state.workers.error);

   return (
      <LoadingOrError loading={loading} error={error}>
         <WorkerForm initialValues={workerItem} onSubmit={onSubmit} />
         <WorkerAdditionalInfo />
      </LoadingOrError>
   );
}
