import React from "react";
import { Field } from "react-final-form";
import { Input } from "reactstrap";

const FormInputSimple = (props) => {
   return (
      <Field { ...props }>
         { ({ input, meta, label }) => (
            <>
               <Input { ...input }
                      disabled={ props.disabled }
                      className="inputField" />
               { meta.touched && meta.error && <label className="error">{ meta.error }</label> }
            </>
         ) }
      </Field>
   );
}

export default FormInputSimple;
