import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar'

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer";
import { SidebarNew } from "components/Sidebar";
import { useSelector } from "react-redux";

import { useFirestoreConnect } from "react-redux-firebase";

import routes from "routes.js";

const AdminFirestoreConnect = () => {
   useFirestoreConnect({
      collection: 'providers',
      // where: [
      //    ['deleted', '==', false]
      // ],
      // orderBy: [
      //    ['name', 'asc']
      // ],
      orderBy: 'name',
      // limit: 2
      // queryParams: [ 'startAt=2']
      // startAfter: 'c',
      // limit: 3
   });
   return null;
};

const ProviderFirestoreConnect = () => {
   const claims = useSelector(state => state.claims && state.claims.data);
   const { provider_id } = claims;

   useFirestoreConnect({ collection: 'providers', doc: provider_id, storeAs: 'provider' });
   useFirestoreConnect({ collection: `providers/${provider_id}/jobs`, storeAs: 'provider_jobs' });

   // useFirestoreConnect({ collection: `providers/${provider_id}/jobs`, where: ['status', '==', JOB_STATUSES.ACTIVE], storeAs: 'provider_jobs_active' });
   // useFirestoreConnect({ collection: `providers/${provider_id}/jobs`, where: ['status', '==', JOB_STATUSES.NOT_PUBLISHED], storeAs: 'provider_jobs_notPublished' });
   // useFirestoreConnect({ collection: `providers/${provider_id}/jobs`, where: ['status', '==', JOB_STATUSES.IN_PROGRESS], storeAs: 'provider_jobs_inProgress' });
   // useFirestoreConnect({ collection: `providers/${provider_id}/jobs`, where: ['status', '==', JOB_STATUSES.DONE], storeAs: 'provider_jobs_done' });
   // useFirestoreConnect({ collection: `providers/${provider_id}/jobs`, where: ['status', '==', JOB_STATUSES.CANCELED], storeAs: 'provider_jobs_canceled' });

   return null;
};

function AdminLayoutNew(props) {
   const claims = useSelector(state => state.claims && state.claims.data);

   const { admin, provider, provider_id } = claims;

   useEffect(() => {
      if (navigator.platform.indexOf("Win") > -1) {
         document.documentElement.className += " perfect-scrollbar-on";
         document.documentElement.classList.remove("perfect-scrollbar-off");
      }

      return () => {
         if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-off";
            document.documentElement.classList.remove("perfect-scrollbar-on");
         }
      }
   })

   const handleMiniClick = () => {
      document.body.classList.toggle("sidebar-mini");
   };

   const getRoutes = routes => {
      return routes.map((prop, key) => {
         if (prop.collapse) {
            return getRoutes(prop.views);
         }
         if (prop.layout === "") {
            return (
               <Route
                  path={ prop.path }
                  component={ prop.component }
                  key={ key }
                  exact={ prop.exact }
               />
            );
         } else {
            return null;
         }
      });
   };

   return (
      <div className="wrapper">
         { admin && <AdminFirestoreConnect/> }
         { provider && provider_id && <ProviderFirestoreConnect/> }
         <SidebarNew
            { ...props }
            routes={ routes }
            bgColor={ 'black' }
            activeColor={ 'info' }
         />
         <PerfectScrollbar className="main-panel">
            <AdminNavbar { ...props } handleMiniClick={ handleMiniClick }/>
            <div className="content">
               <Switch>
                  { getRoutes(routes) }
                  <Redirect to="/dashboard"/>
               </Switch>
            </div>
            { props.location.pathname.indexOf("full-screen-map") !== -1 ? null : <Footer fluid/> }
         </PerfectScrollbar>
      </div>
   );
}

export default AdminLayoutNew;
