import React from 'react';
import { Button, Row, Col } from 'reactstrap';

export default function CardItem(props) {
   return (
      <Row className='pl-1'>
         <Col md={11}>
            {props.children}
         </Col>
         <Col md={1}>
            <Button
               onClick={() => props.onClick(props.index)}
               className="btn-round btn-icon">
               <i className="fa fa-edit"></i>
            </Button>
         </Col>
      </Row>
   )
}
