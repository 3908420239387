import NumberFormat from "react-number-format";
import React from "react";

const NumberToCurrency = ({ value }) => (
   <NumberFormat value={ value }
                 displayType={ 'text' }
                 decimalScale={ 2 }
                 fixedDecimalScale
                 thousandSeparator={ true }
                 prefix={ '$ ' }/>
);

export default NumberToCurrency;
