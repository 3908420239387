import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar'

import SignIn from "modules/auth/views/SignIn";
import SignUp from "modules/auth/views/SignUp";
import PasswordReset from "modules/auth/views/PasswordReset";

import AuthNavbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footer";

export default function AuthLayout() {
   return (
      <>
         <AuthNavbar />
         <PerfectScrollbar className="wrapper wrapper-full-page" options={{ suppressScrollX: true, suppressScrollY: false }}>
            <div className="full-page section-image">
               <Switch>
                  <Route path="/auth/signin" component={ SignIn }/>
                  <Route path="/auth/signup" component={ SignUp }/>
                  <Route path="/auth/password" component={ PasswordReset }/>
                  <Redirect to="/auth/signin" />
               </Switch>
               <Footer fluid />
            </div>
         </PerfectScrollbar>
      </>
   )
}
