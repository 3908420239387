import React from 'react';
import { Modal, Button } from 'reactstrap';
import { Form } from 'react-final-form'

export default function BasicModalForm({ children, isOpen, onModalClose, onSubmit, initialValues = {}, title }) {
   return (
      <Modal isOpen={ isOpen } toggle={ onModalClose }>
         <Form onSubmit={ onSubmit }
               initialValues={ initialValues }
               render={ ({ handleSubmit, values }) => (
                  <form onSubmit={ handleSubmit }>
                     <div className="modal-header justify-content-center">
                        <button
                           aria-label="Close"
                           className="close"
                           data-dismiss="modal"
                           type="button"
                           onClick={ onModalClose }
                        >
                           <i className="nc-icon nc-simple-remove"/>
                        </button>
                        <h4 className="title title-up">{ title }</h4>
                     </div>
                     <div className="modal-body">
                        { children }
                     </div>
                     <div className="modal-footer" style={ { flexWrap: 'nowrap' } }> { /* TODO: FIX ME ASAP!! */ }
                        <div className="left-side">
                           <Button
                              className="btn-link"
                              color="default"
                              data-dismiss="modal"
                              type="button"
                              onClick={ onModalClose }
                           >
                              Cancel
                           </Button>
                        </div>
                        <div className="divider"/>
                        <div className="right-side">
                           <Button
                              className="btn-link"
                              color="primary"
                              type="submit"
                           >
                              Save changes
                           </Button>
                        </div>
                     </div>
                  </form>
               ) }/>
      </Modal>
   )
};
