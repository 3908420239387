import Dashboard from 'modules/dashboard/views/Dashboard';
import Calendar from 'modules/calendar/views/Calendar';
import Jobs from 'modules/jobs/views/Jobs';
import JobPage from 'modules/jobs/views/JobPage';
import Reports from 'modules/reports/views/Reports';

import UsersPage from 'modules/users/views/UsersPage';
import UserPage from 'modules/users/views/UserPage';

import Providers from 'modules/providers/views/ProvidersPage';
import ProviderPage from 'modules/providers/views/ProviderPage';

import WorkersPage from 'modules/workers/views/WorkersPage';
import WorkerPage from 'modules/workers/views/WorkerPage';

import ProfilePage from 'modules/profile/views/Profile';
import SettingsPage from 'modules/settings/views/Settings';
import BillingPage from 'modules/billing/views/Billing';

const routes = [
   {
      path: "/dashboard",
      name: "Dashboard",
      icon: "nc-icon nc-bank",
      component: Dashboard,
      layout: ""
   },
   {
      path: "/profile",
      name: "My Profile",
      icon: "nc-icon nc-bank",
      component: ProfilePage,
      layout: "",
      noSideMenu: true,
   },
   {
      path: "/billing",
      name: "Billing",
      icon: "nc-icon nc-bank",
      component: BillingPage,
      layout: "",
      noSideMenu: true
   },
   {
      path: "/settings",
      name: "Settings",
      icon: "nc-icon nc-bank",
      component: SettingsPage,
      layout: "",
      noSideMenu: true
   },
   {
      path: "/calendar",
      name: "Calendar",
      icon: "nc-icon nc-bank",
      component: Calendar,
      layout: ""
   },
   {
      path: "/jobs",
      name: "Jobs",
      icon: "nc-icon nc-bank",
      component: Jobs,
      layout: ""
   },
   {
      path: "/job/new",
      name: "New Job",
      icon: "nc-icon nc-bank",
      component: JobPage,
      layout: "",
      noSideMenu: true,
      exact: true
   },
   {
      path: "/job/:id",
      name: "Edit Job",
      icon: "nc-icon nc-bank",
      component: JobPage,
      layout: "",
      noSideMenu: true,
      strict: true
   },
   {
      path: "/reports",
      name: "Reports",
      icon: "nc-icon nc-bank",
      component: Reports,
      layout: ""
   },
   {
      path: "/providers",
      name: "Providers",
      icon: "nc-icon nc-bank",
      component: Providers,
      layout: "",
      onlyAdmin: true,
      exact: true
   },
   {
      path: "/provider/new",
      name: "New Provider",
      icon: "nc-icon nc-bank",
      component: ProviderPage,
      layout: "",
      onlyAdmin: true,
      noSideMenu: true,
      exact: true
   },
   {
      path: "/provider/:id",
      name: "Edit Provider",
      icon: "nc-icon nc-bank",
      component: ProviderPage,
      layout: "",
      onlyAdmin: true,
      noSideMenu: true
   },
   {
      path: "/workers",
      name: "Workers",
      icon: "nc-icon nc-bank",
      component: WorkersPage,
      layout: "",
      onlyAdmin: true,
      exact: true
   },
   {
      path: "/worker/new",
      name: "New Worker",
      icon: "nc-icon nc-bank",
      component: WorkerPage,
      layout: "",
      onlyAdmin: true,
      noSideMenu: true,
      exact: true
   },
   {
      path: "/worker/:id",
      name: "Edit Worker",
      icon: "nc-icon nc-bank",
      component: WorkerPage,
      layout: "",
      onlyAdmin: true,
      noSideMenu: true
   },
   {
      path: "/users",
      name: "Users",
      icon: "nc-icon nc-bank",
      component: UsersPage,
      layout: "",
      onlyAdmin: true,
      exact: true
   },
   {
      path: "/user/new",
      name: "New User",
      icon: "nc-icon nc-bank",
      component: UserPage,
      layout: "",
      onlyAdmin: true,
      noSideMenu: true,
      exact: true
   },
   {
      path: "/user/:id",
      name: "Edit User",
      icon: "nc-icon nc-bank",
      component: UserPage,
      layout: "",
      onlyAdmin: true,
      noSideMenu: true
   }
];

export default routes;
