export const FETCH_WORKERS = "FETCH_WORKERS";
export const FETCH_WORKERS_SUCCESS = "FETCH_WORKERS_SUCCESS";
export const FETCH_WORKERS_ERROR = "FETCH_WORKERS_ERROR";
export const SELECT_WORKER = "SELECT_WORKER";
export const SELECT_WORKER_SUCCESS = "SELECT_WORKER_SUCCESS";
export const SELECT_WORKER_ERROR = "SELECT_WORKER_ERROR";
export const ADD_WORKER = "ADD_WORKER";
export const ADD_WORKER_SUCCESS = "ADD_WORKER_SUCCESS";
export const ADD_WORKER_ERROR = "ADD_WORKER_ERROR";
export const EDIT_WORKER = "EDIT_WORKER";
export const EDIT_WORKER_SUCCESS = "EDIT_WORKER_SUCCESS";
export const EDIT_WORKER_ERROR = "EDIT_WORKER_ERROR";
export const ADD_ADDITIONAL_WORKER_INFO = 'ADD_ADDITIONAL_WORKER_INFO';
export const ADD_ADDITIONAL_WORKER_INFO_SUCCESS = 'ADD_ADDITIONAL_WORKER_INFO_SUCCESS';
export const ADD_ADDITIONAL_WORKER_INFO_ERROR = 'ADD_ADDITIONAL_WORKER_INFO_ERROR';
