import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { fetchUsers } from '../users.actions';
import { Table, LoadingOrError } from "components"

function UsersPage() {
   const loading = useSelector(state => state.users.loader);
   const error = useSelector(state => state.users.error);
   const users = useSelector(state => state.users.items);

   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(fetchUsers())
   }, [dispatch]);

   return (
      <LoadingOrError loading={ loading && users.length === 0 } error={ error }>
         <Card>
            <CardBody>
               <Table headers={ ['Email', 'Firebase', 'Type'] }
                      fields={ ['email', 'uid', 'user_type'] }
                      actions={ [
                         ({ row }) => <Link className="btn btn-primary btn-sm btn-fill"
                                            to={ `/user/${ row._id }` }>Edit</Link>,
                      ] }
                      rows={ users }/>
            </CardBody>
         </Card>
         <Link className="btn btn-default btn-fill btn-wd" to="/user/new">Add New User</Link>
      </LoadingOrError>
   );
}

export default UsersPage;
