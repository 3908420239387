import React, { useEffect, useState } from "react";
import { isEmpty, useFirebase } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import {
   Button,
   Card,
   CardHeader,
   CardBody,
   CardFooter,
   Form,
   Input,
   InputGroupAddon,
   InputGroupText,
   InputGroup,
   Container,
   Col,
   Row
} from "reactstrap";

function LoginPage() {
   useEffect(() => {
      document.body.classList.toggle("login-page");

      return () => {
         document.body.classList.toggle("login-page");
      }
   }, []);

   const firebase = useFirebase();
   const auth = useSelector((state) => state.firebase.auth);

   const [email, setEmail] = useState("omar@balkon.ba");
   const [password, setPassword] = useState("Sifra123");

   if (!isEmpty(auth)) {
      return <Redirect to="/" />;
   }

   const handleSubmit = (event) => {
      event && event.preventDefault();
      loginWithGoogle(email, password);
   };

   function loginWithGoogle(email, password) {
      firebase.login({ email, password }).catch(function (error) {
         console.error("-----------------------");
         console.error(error);
         console.error("-----------------------");
      });
   }

   return (
      <div className="login-page">
         <Container>
            <Row>
               <Col className="ml-auto mr-auto" lg="4" md="6">
                  <Form action="" className="form" method="" onSubmit={handleSubmit}>
                     <Card className="card-login">
                        <CardHeader>
                           <CardHeader>
                              <h3 className="header text-center">Sign In</h3>
                           </CardHeader>
                        </CardHeader>
                        <CardBody>
                           <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                 <InputGroupText>
                                    <i className="nc-icon nc-email-85" />
                                 </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="Email"
                                     type="email"
                                     required
                                     onChange={(event) => setEmail(event.target.value)}
                                     value={email} />
                           </InputGroup>
                           <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                 <InputGroupText>
                                    <i className="nc-icon nc-key-25" />
                                 </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                 placeholder="Password"
                                 type="password"
                                 autoComplete="off"
                                 onChange={(event) => setPassword(event.target.value)}
                                 value={password}
                              />
                           </InputGroup>
                        </CardBody>
                        <CardFooter>
                           <Button
                              block
                              type="submit"
                              className="btn-round mb-3"
                              color="warning"
                           >
                              Sign In
                           </Button>
                        </CardFooter>
                     </Card>
                  </Form>
               </Col>
            </Row>
         </Container>
         <div
            className="full-page-background"
            style={{
               backgroundImage: `url(${require("assets/images/fabio-mangione.jpg")})`
            }}
         />
      </div>
   );
}

export default LoginPage;
