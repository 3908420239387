import React from "react";
import Loader from "components/Loader";

const LoadingOrError = ({ loading, error, children }) => {
  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p className="text-danger">{error}</p>;
  }

  return children;
};

export default LoadingOrError;
