import axios from "axios";
import { getFirebase } from "react-redux-firebase";

const axiosInstance = axios.create({
  baseURL: 'http://localhost:3001/api'
});

axiosInstance.interceptors.request.use(
  async config => {
    const token = await getFirebase().auth().currentUser.getIdToken();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
