import React from "react";
import { useParams } from "react-router-dom";

import ProviderPageNew from "../components/ProviderPageNew";
import ProviderPageEdit from "../components/ProviderPageEdit";

export default function Provider() {
    const { id } = useParams();

    return id ? <ProviderPageEdit /> : <ProviderPageNew />

}
