import React from "react";
import { Col, Row } from "reactstrap";
import NumberFormat from "react-number-format";
import { Field } from 'react-final-form';

export default function JobShiftsSummary({ totalPayment, totalFee, totalWorkerPart }) {
   return (
      <>
         <Field name="totalPayment" component="input" type="hidden"/>
         <Field name="totalFee" component="input" type="hidden"/>
         <Field name="totalWorkerPart" component="input" type="hidden"/>

         <h3 style={ { marginBottom: '0px' } }>Shifts Summary</h3>
         <hr/>
         <Row>
            <Col md={ 3 } className="text-right">
               <strong>
                  <NumberFormat value={ totalPayment }
                                displayType={ 'text' }
                                decimalScale={ 2 }
                                fixedDecimalScale
                                thousandSeparator={ true }
                                prefix={ '$ ' }/>
               </strong>
            </Col>
            <Col>
               <strong>Total Job Payment</strong>
            </Col>
         </Row>
         <Row>
            <Col md={ 3 } className="text-right">
               <strong>
                  <NumberFormat value={ totalFee }
                                displayType={ 'text' }
                                decimalScale={ 2 }
                                fixedDecimalScale
                                thousandSeparator={ true }
                                prefix={ '$ ' }/>
               </strong>
            </Col>
            <Col>Total Job Fee</Col>
         </Row>
         <Row>
            <Col md={ 3 } className="text-right">
               <strong>
                  <NumberFormat value={ totalWorkerPart }
                                displayType={ 'text' }
                                decimalScale={ 2 }
                                fixedDecimalScale
                                thousandSeparator={ true }
                                prefix={ '$ ' }/>
               </strong>
            </Col>
            <Col>Worker's Part</Col>
         </Row>
      </>
   );
}
