import { Col, InputGroup, Row } from "reactstrap";
import React, { useContext } from "react";
import { Field } from "react-final-form";
import NumberFormat from "react-number-format";

import FormRadioButton from "components/form/FormRadioButton";
import ReadOnlyContext from "components/ReadOnlyContext";

import Condition from 'components/Condition';
import { PERCENTAGE_FEE } from "modules/jobs/jobs.constants";

const PaymentCurrencyInput = (props) => {
   const isReadOnly = useContext(ReadOnlyContext);

   return (
      <Field { ...props }>
         {
            ({ input, meta: { touched, error, warning }, disabled }) => (
               <InputGroup>
                  <NumberFormat
                     decimalScale={ 2 }
                     fixedDecimalScale
                     allowNegative={ false }
                     className="form-control currency-input"
                     thousandSeparator={ true }
                     prefix={ '$ ' }
                     value={ input.value }
                     disabled={ disabled }
                     onValueChange={ value => input.onChange(value.floatValue) }
                     isAllowed={ values => values.floatValue <= 10000 }
                     readOnly={isReadOnly}
                  />
                  { touched && error && <label className="error">{ error }</label> }
               </InputGroup>
            )
         }
      </Field>
   );
};



export default function JobPaymentMethod({ percentageFee }) {
   const isReadOnly = useContext(ReadOnlyContext);

   return (
      <>
         <h4 style={ { marginBottom: '0px', marginTop: '0px' } }>Payment Type</h4>
         <hr/>
         <Col xs={ 12 }>
            <Row>
               <FormRadioButton name="paymentType"
                                label="Hourly"
                                type="radio"
                                value="hourly"
                                disabled={ isReadOnly }/>
               <FormRadioButton name="paymentType"
                                label="By Shift"
                                type="radio"
                                value="byShift"
                                disabled={ isReadOnly }/>
            </Row>
            <Condition when="paymentType" is="hourly">
               <Row>
                  <Col md={ 4 }>
                     <PaymentCurrencyInput name="hourlyRate"/>
                  </Col>
                  <Col style={ { paddingLeft: '0px', paddingTop: '10px' } }>
                     <strong>Hourly Rate</strong>
                  </Col>
               </Row>
               <Row>
                  <Col md={ 4 }>
                     <PaymentCurrencyInput name="hourlyFee" disabled/>
                  </Col>
                  <Col style={ { paddingLeft: '0px', paddingTop: '10px' } }>
                     Jeri Service fee is <strong>({ PERCENTAGE_FEE }) %</strong>
                  </Col>
               </Row>
               <Row>
                  <Col md={ 4 }>
                     <PaymentCurrencyInput name="hourlyWorkersPart"/>
                  </Col>
                  <Col style={ { paddingLeft: '0px', paddingTop: '10px' } }>
                     Worker's part
                  </Col>
               </Row>
            </Condition>
            <Condition when="paymentType" is="byShift">
               <span>
                  You will define payment amount for <strong>each shift</strong>. A worker will receive shift
                  payment amount reduced by <strong>{ percentageFee }% of Service Fee</strong>.
               </span>
            </Condition>
         </Col>
         <br/>
         <br/>
      </>
   );
}
