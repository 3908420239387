import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardFooter, CardBody, Row, Col } from 'reactstrap';

import { DATE_TIME_FORMAT } from 'jeri.constants';
import { format } from "date-fns";

import NumberToCurrency from 'components/NumberToCurrency'
import { JobTypes } from "../jobs.constants";

export default function JobCard({ job }) {
   const CardRow = ({ label, value }) => (
      <Row>
         <Col xs={ 4 }>{ label }:</Col>
         <Col><strong>{ value }</strong></Col>
      </Row>
   );

   return (
      <Card>
         <CardBody>
            <h5>{ JobTypes.find(x => x.value === job.job_type).label }</h5>
            <CardRow label="Start" value={ format(job.jobStart.toDate(), DATE_TIME_FORMAT) }/>
            <CardRow label="End" value={ format(job.jobEnd.toDate(), DATE_TIME_FORMAT) }/>
            <br />
            <CardRow label="Payment Type" value={ job.paymentType }/>
            <CardRow label="Shifts" value={ job.shifts.length }/>
            <br />
            <CardRow label="Payment" value={ <NumberToCurrency value={job.totalPayment} /> }/>
            <CardRow label="Fee" value={ <NumberToCurrency value={job.totalFee} /> }/>
            <CardRow label="Worker" value={ <NumberToCurrency value={job.totalWorkerPart} /> }/>
         </CardBody>
         <CardFooter>
            <Link className="btn btn-sm" to={ `/job/${ job.DocumentID }` }>
               OPEN
            </Link>
            <span className="pull-right" style={{ color: '#a1a1a1' }}>{ job.status }</span>
         </CardFooter>
      </Card>
   )
}
