import React from 'react';
import { useSelector } from 'react-redux';

import WorkerCard from '../WorkerCard';
import SpecialityForm from "./SpecialityForm"

export default function SpecialtiesCard(props) {
   const cardName = 'specialties';
   const cardTitle = 'Specialties';

   const worker = useSelector(state => state.workers.selected);
   const specialties = worker ? worker[cardName] : [];

   const SpecialtyItem = ({ item }) => {
      return (
         <div>
            <h5>{item.name}</h5>
            <p>{item.obtained}</p>
            <p>{item.extra}</p>
            <p>{item.validFrom} - {item.validTo}</p>
         </div>
      )
   }

   return (
      <WorkerCard
         title={cardTitle}
         cardName={cardName}
         dataArray={specialties}
         ItemInfo={SpecialtyItem}
         Form={SpecialityForm}
      />
   )
}
