import React from 'react';
import { FormInput } from "components/form"
import { DefaultLength, Date, ComposeValidators, Required } from "validators";

function LicencesForm() {
   return (
      <>
         <FormInput validate={ ComposeValidators(Required, DefaultLength) } name="name" label="Name" type='text' />
         <FormInput validate={ ComposeValidators(Required, Date, DefaultLength) } name="obtained" label="Obtained" type='date' />
         <FormInput validate={ ComposeValidators(Required, DefaultLength) } name="validFrom" label="ValidFrom" type='text' />
         <FormInput validate={ ComposeValidators(Required, DefaultLength) } name="validTo" label="ValidTo" type='text' />
         <FormInput validate={ ComposeValidators(DefaultLength) } name="extra" label="Extra" type='text' />
      </>
   )
}

export default LicencesForm;
