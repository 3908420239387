import * as types from './users.types';

const INIT_STATE = {
    items: [],
    loader: false,
    error: null
}

export default function providerReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case types.FETCH_USERS:
            return {
                ...state,
                loader: true,
                error: null
            };
       case types.FETCH_USERS_SUCCESS:
            return {
                ...state,
                items: [...action.items],
                loader: false
            };
        case types.FETCH_USER_SUCCESS:
            return {
                ...state,
                items: [
                    ...state.items,
                    {
                        ...action.item,
                        admin: action.item.is_admin ? 'Admin': ''
                    }
                ],
                loader: false
            };
       case types.UPDATE_USER_SUCCESS:
          return {
             ...state,
             items: state.items.map(oldItem => oldItem._id === action.item._id ? { ...action.item } : { ...oldItem }),
             loader: false
          };
       case types.ADD_USER_SUCCESS:
          return {
             ...state,
             items: [
                ...state.items,
                action.item
             ],
             loader: false
          };
        case types.FETCH_USERS_ERROR:
            return {
                ...state,
                loader: false,
                error: action.error,
            };
        default:
            return state;
    }
}
