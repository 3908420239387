import React from 'react';
import { useSelector } from 'react-redux';

import WorkerCard from '../WorkerCard';
import LicencesForm from './LicencesForm';

export default function LicensesCard(props) {
   const cardName = 'licenses';
   const cardTitle = 'Licenses';

   const worker = useSelector(state => state.workers.selected);
   const licenses = worker ? worker[cardName] : [];

   const LicenseItem = ({ item }) => {
      return (
         <div>
            <h5>{item.name}</h5>
            <p>{item.obtained}</p>
            <p>{item.validFrom} - {item.validTo}</p>
            <p>{item.extra}</p>
         </div>
      )
   }


   return (
      <WorkerCard
         title={cardTitle}
         cardName={cardName}
         dataArray={licenses}
         ItemInfo={LicenseItem}
         Form={LicencesForm}
      />
   )
}
