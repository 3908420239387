import React from 'react';
import { FormInput } from "components/form"
import { Empty, DefaultLength, Date, ComposeValidators, Required } from "validators";

function VaccinationForm() {
   return (
      <>
         <FormInput validate={ ComposeValidators(Required, DefaultLength) } name="name" label="Name" type="text" />
         <FormInput validate={ ComposeValidators(Date, Empty) } name="dateTaken" label="Date taken" type="date" />
      </>
   )
}

export default VaccinationForm;
