import { Col, Row } from "reactstrap";
import { chartExample5, chartExample6, chartExample7 } from "../../../charts";
import React from "react";
import { BottomStatCard } from "./BottomStatCard";

export function BottomStatCards() {
   return (
      <Row>
         <Col md="3">
            <BottomStatCard title="Email Statistics"
                            subTitle="Last Campaign Performance"
                            legend="Open"
                            footerTitle="Number of emails sent"
                            footerIcon="fa-calendar"
                            doughnutData={chartExample5} />
         </Col>
         <Col md="3">
            <BottomStatCard title="New Visitators"
                            subTitle="Out Of Total Number"
                            legend="Visited"
                            footerTitle="Campaign sent 2 days ago"
                            footerIcon="fa-check"
                            doughnutData={chartExample6} />
         </Col>
         <Col md="3">
            <BottomStatCard title="Orders"
                            subTitle="Total Number"
                            legend="Completed"
                            footerTitle="Updated 3 minutes ago"
                            footerIcon="fa-clock-o"
                            doughnutData={chartExample7} />
         </Col>
         <Col md="3">
            <BottomStatCard title="Email Statistics"
                            subTitle="Last Campaign Performance"
                            legend="Open"
                            footerTitle="Number of emails sent"
                            footerIcon="fa-calendar"
                            doughnutData={chartExample5} />
         </Col>
      </Row>
   );
}
