import React from 'react';
import { useSelector } from 'react-redux';

import EducationForm from "./EducationForm"
import WorkerCard from '../WorkerCard';

export default function EducationCard(props) {
   const cardName = 'education';
   const cardTitle = 'Education';

   const worker = useSelector(state => state.workers.selected);
   const education = worker ? worker[cardName] : [];

   const EducationItem = ({ item }) => {
      return (
         <div>
            <h5>{item.school}</h5>
            <p>{item.degree} in {item.field}</p>
            <p>{item.started} - {item.ended}</p>
            <p>{item.extra}</p>
         </div>
      )
   }

   return (
         <WorkerCard
            title={cardTitle}
            cardName={cardName}
            dataArray={education}
            ItemInfo={EducationItem}
            Form={EducationForm}
         />
   )
}
