import React from "react";
import { Field } from 'react-final-form'

import { FormGroup, Input, Label } from "reactstrap";

const FormRadioButton = ({ input, label, meta: { touched, error }, disabled }) => {
   return (
      <FormGroup check className={`has-label ${touched && error && "has-danger"}`}>
         <Label check>
            <Input {...input} type={"radio"} disabled={disabled} /> {label}
         </Label>
         {touched && error && <label className="error">{error}</label>}
      </FormGroup>
   );
};

const FieldRadioButton = (props) => {
   return <Field {...props} component={FormRadioButton} />;
};

export default FieldRadioButton;
