import React, { useContext } from "react";
import { Field } from 'react-final-form'

import {
   FormGroup,
   Label
} from "reactstrap";
import Select from 'react-select'
import ReadOnlyContext from 'components/ReadOnlyContext';

const FieldSelect = (props) => {
   const isReadOnly = useContext(ReadOnlyContext);

   return (
      <Field { ...props }
             parse={item => item.value}
             format={value => value ? props.options && props.options.find(x => x.value === value) : null}
      >
         {
            ({ input, label, meta: { touched, error }, options, id, name }) => (
               <FormGroup className={ `has-label ${ touched && error && 'has-danger' }` }>
                  <Label>{ label }</Label>
                  <Select
                     { ...input }
                     simpleValue={true}
                     id={ id }
                     name={ name }
                     options={ options }
                     value={ input.value }
                     onChange={ (value) => input.onChange(value) }
                     onBlur={ (value) => input.onBlur() }
                     className="select-input"
                     isDisabled={isReadOnly}
                  />
                  { touched && error && <label className="error">{ error }</label> }
               </FormGroup>
            )
         }
      </Field>
   )
}

export default FieldSelect;
