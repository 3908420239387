import * as firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
  apiKey: "AIzaSyDJZ5c71FdbeFIB_JUVOt8Ka1dGxWriqU4",
  authDomain: "jeri-platform.firebaseapp.com",
  databaseURL: "https://jeri-platform.firebaseio.com",
  projectId: "jeri-platform",
  storageBucket: "jeri-platform.appspot.com",
  messagingSenderId: "886335393262",
  appId: "1:886335393262:web:d3d856e2ebf0051e1352ff",
  measurementId: "G-YDZ5XZNXJ6"
}

firebase.initializeApp(config);

if (window.location.hostname === "localhost") {
   const db = firebase.firestore();
   db.settings({
      host: "localhost:8025",
      ssl: false
   });
}

export default firebase;
