import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Table, LoadingOrError } from "components";
import { Card, CardBody } from "reactstrap";
import fsObjectToArray from "helpers/fsObjectToArray";

function ProvidersPage() {
   const loader = useSelector((state) => state.providers.loader);
   const error = useSelector((state) => state.providers.error);

   const providers = useSelector(state => state.firestore.data.providers);

   const TableEditButton = ({ row }) => {
      return (
         <Link className="btn btn-primary btn-sm btn-fill" to={`/provider/${row.DocumentID}`}>
            Edit
         </Link>
      );
   };

   return (
      <LoadingOrError loading={loader} error={error}>
         <Card>
            <CardBody>
               <Table
                  headers={["Name", "State", "City", "Address"]}
                  fields={["name", "state", "city", "address"]}
                  actions={[TableEditButton]}
                  rows={fsObjectToArray(providers)}
               />
            </CardBody>
         </Card>
         <Link className="btn btn-fill btn-wd" to={"/provider/new"}>
            Add new provider
         </Link>
      </LoadingOrError>
   );
}

export default ProvidersPage;
