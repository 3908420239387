import React, {useState} from "react";

export function Pre({object}) {
    const [visible, setVisible] = useState(false);

    if (process.env.NODE_ENV === 'development') {
       return (
          <div>
            <span onClick={() => setVisible(!visible)}
                  style={{cursor: 'pointer', color: '#7b7b7b'}}>show / hide object</span>
             {visible && <pre>{JSON.stringify(object, null, 3)}</pre>}
          </div>
       )
    }

    return null;
}
