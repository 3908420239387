import React from "react";
import { Col, Row } from "reactstrap";
import { FormInput } from "components/form";
import { ComposeValidators, DefaultLength, Required, SelectRequired } from "validators";
import FieldSelect from "components/form/FormSelect";

import { JobTypes } from "modules/jobs/jobs.constants";

export default function JobDetails({ values }) {
   return (
      <>
         <Row>
            <Col md={ 6 }>
               <h4 style={ { marginBottom: '0px', marginTop: '0px' } }>Job Details</h4>
            </Col>
            <Col md={ 6 }>
               <h4 className="pull-right" style={{ color: '#a1a1a1', marginBottom: '0px', marginTop: '0px' }}>{ values.status }</h4>
            </Col>
         </Row>

         <hr/>
         <Row>
            <Col md={ 6 } sm={ 12 }>
               <FormInput
                  name="name"
                  label="Job Name"
                  validate={ ComposeValidators(Required, DefaultLength) }
               />
            </Col>
            <Col md={ 6 } sm={ 12 }>
               <FieldSelect
                  options={ JobTypes }
                  validate={ ComposeValidators(SelectRequired) }
                  label="Job Type"
                  name="job_type"
                  parse={item => item.value}
                  format={value => value ? JobTypes.find(x => x.value === value) : null}
               />
            </Col>
            <Col md={ 12 } sm={ 12 }>
               <FormInput
                  type={"textarea"}
                  name="description"
                  label="Job Description"
                  validate={ Required }
               />
            </Col>
         </Row>
      </>
   );
}
