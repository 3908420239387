import React, { useState } from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";

import {
   Collapse,
   NavbarBrand,
   Navbar,
   NavItem,
   Nav,
   Container
} from "reactstrap";

function AuthNavbar() {
   const [color, setColor] = useState('navbar-transparent');
   const [collapseOpen, setCollapseOpen] = useState(false);

   function toggleCollapse() {
      if (!collapseOpen) {
         setColor("bg-white");
      } else {
         setColor("navbar-transparent");
      }
      setCollapseOpen(!collapseOpen);
   }

   return (
      <Navbar className={ classnames("navbar-absolute fixed-top", color) } expand="lg">
         <Container>
            <div className="navbar-wrapper">
               <NavbarBrand href="/" onClick={ e => e.preventDefault() }>
                  Jeri Platform
               </NavbarBrand>
            </div>
            <button
               aria-controls="navigation-index"
               aria-expanded={ false }
               aria-label="Toggle navigation"
               className="navbar-toggler"
               data-toggle="collapse"
               type="button"
               onClick={ toggleCollapse }
            >
               <span className="navbar-toggler-bar navbar-kebab"/>
               <span className="navbar-toggler-bar navbar-kebab"/>
               <span className="navbar-toggler-bar navbar-kebab"/>
            </button>
            <Collapse
               isOpen={ collapseOpen }
               className="justify-content-end"
               navbar
            >
               <Nav navbar>
                  <NavItem>
                     <NavLink to="/auth/signin" className="nav-link">
                        <i className="nc-icon nc-tap-01"/>
                        Sign In
                     </NavLink>
                  </NavItem>
                  <NavItem>
                     <NavLink to="/auth/signup" className="nav-link">
                        <i className="nc-icon nc-book-bookmark"/>
                        Sign Up
                     </NavLink>
                  </NavItem>
               </Nav>
            </Collapse>
         </Container>
      </Navbar>
   );
}

export default AuthNavbar;
