import React from "react";
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import { Line } from "react-chartjs-2";

export function ChartStatCard({ value, percentage, bigTitle, footerTitle, lineData, color, smallIcon }) {
   return (
      <Card>
         <CardHeader>
            <Row>
               <Col sm="7">
                  <div className="numbers pull-left">{ value }</div>
               </Col>
               <Col sm="5">
                  <div className="pull-right">
                     <Badge color={ color || 'success' } pill>
                        { percentage }
                     </Badge>
                  </div>
               </Col>
            </Row>
         </CardHeader>
         <CardBody>
            <h6 className="big-title">
               { bigTitle }
            </h6>
            <Line
               data={ lineData.data }
               options={ lineData.options }
               height={ 380 }
               width={ 826 }
            />
         </CardBody>
         <CardFooter>
            <hr/>
            <Row>
               <Col sm="7">
                  <div className="footer-title">{ footerTitle }</div>
               </Col>
               <Col sm="5">
                  <div className="pull-right">
                     <Button
                        className="btn-round btn-icon"
                        color={ color || 'success' }
                        size="sm"
                     >
                        <i className={ `nc-icon ${ smallIcon }` }/>
                     </Button>
                  </div>
               </Col>
            </Row>
         </CardFooter>
      </Card>
   );
}
