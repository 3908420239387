import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { claims } from "modules/auth/auth.actions";
import Loader from "components/Loader";
import firebase from 'firebase.js';
import { Redirect } from "react-router-dom";

function AuthIsLoaded({ children }) {
   const dispatch = useDispatch();
   const auth = useSelector(state => state.firebase.auth)
   const claimsData = useSelector(state => state.claims)


   useEffect(() => {
      if (auth && isLoaded(auth)) {
         dispatch(claims());
      }
   }, [dispatch, auth]);

   if (!isLoaded(auth) || !claimsData.isLoaded) {
      return <Loader/>;
   }

   // Only Jeri Admin & Provider Admin can use Dashboard
   if (!isEmpty(auth) && claimsData.isLoaded && claimsData.data.user_id) {
      if (!claimsData.data.admin && !claimsData.data.provider) {
         firebase.auth().signOut();
         return <Redirect to="/auth/signin"/>;
      }
      // if (['admin', 'provider'].indexOf(claimsData.data.user_type) === -1) {
      //    firebase.auth().signOut();
      //    return <Redirect to="/auth/signin"/>
      // }
   }

   return children;
}

export default AuthIsLoaded;
