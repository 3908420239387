import * as claimsTypes from './auth.types';

import { getFirebase } from "react-redux-firebase";

export const claims = () => {
  return async dispatch => {
    dispatch(claimsStarted());

    try {
      if (getFirebase().auth().currentUser) {
        const tokenResult = await getFirebase().auth().currentUser.getIdTokenResult();
        dispatch(claimsSuccess(tokenResult.claims));
      } else {
        dispatch(claimsSuccess(null));
      }

    } catch (err) {
      dispatch(claimsFailure(err.message));
    }
  };
};

const claimsSuccess = demo => ({
  type: claimsTypes.CLAIMS_SUCCESS,
  payload: {
    ...demo
  }
});

const claimsStarted = () => ({
  type: claimsTypes.CLAIMS_STARTED
});

const claimsFailure = error => ({
  type: claimsTypes.CLAIMS_FAILURE,
  payload: {
    error
  }
});
