import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import { LoadingOrError } from "components";

import createDecorator from 'final-form-calculate';
import arrayMutators from 'final-form-arrays';

import "react-datepicker/dist/react-datepicker.css";

import { Form } from 'react-final-form';
import { addMinutes, differenceInMinutes, max, min } from "date-fns";

import {
   JobDetails,
   JobPaymentMethod,
   JobShifts,
   JobShiftsSummary,
   formatMinutes,
   parseMinutes
} from ".";

import {
   JOB_STATUSES,
   PAYMENT_TYPE,
   HOURLY_FEE,
   PERCENTAGE_FEE,
   HOURLY_RATE,
   HOURLY_WORKERS_PART,
   SHIFT_SIZE_MINUTES
} from "modules/jobs/jobs.constants";

import { Pre } from "components/Pre";
import { ReadOnlyProvider } from "components/ReadOnlyContext";

function CalculateShiftsPaymentSummary({ paymentType, shifts, percentageFee, hourlyFee }) {
   if (!shifts) {
      return {
         totalPayment: 0,
         totalFee: 0,
         totalWorkerPart: 0
      };
   }

   const totalPayment = shifts.reduce((total, b) => total + b.amount || 0, 0);
   const sumMinutes = shifts.reduce((total, b) => total + b.minutes || 0, 0);

   let totalFee = 0;
   if (paymentType === 'hourly') {
      totalFee = (sumMinutes / 60) * hourlyFee;
   }
   if (paymentType === 'byShift') {
      totalFee = (percentageFee / 100) * totalPayment;
   }

   const totalWorkerPart = totalPayment - totalFee;

   return {
      totalPayment,
      totalFee,
      totalWorkerPart
   }
}

function CalculateJobStartEnd({ shifts }) {
   const jobStartStop = {
      jobStart: null,
      jobEnd: null
   };
   if (shifts && shifts.length) {
      jobStartStop.jobStart = min(shifts.map(e => new Date(e.start)));
      jobStartStop.jobEnd = max(shifts.map(e => new Date(e.end)));
   }
   return jobStartStop;
}

const calculator = createDecorator(
   {
      field: 'paymentType',
      updates: (value, name, allValues, prevValues) => {
         if (!prevValues.paymentType) {
            return {};
         }

         const shifts = {}
         // reset minutes and minutesStr to default
         if (allValues.paymentType === 'byShift') {
            allValues.shifts.forEach((s, index) => {
               shifts[`shifts[${ index }].minutes`] = differenceInMinutes(new Date(s.end), new Date(s.start));
               shifts[`shifts[${ index }].minutesStr`] = formatMinutes(differenceInMinutes(new Date(s.end), new Date(s.start)));
            })
         }

         return {
            ...shifts
         }
      }
   },
   {
      field: 'hourlyRate',
      updates: (value, name, allValues, prevValues) => {
         if (!prevValues.hourlyRate) {
            return {};
         }

         const shifts = {}
         if (allValues.paymentType === 'hourly') {
            allValues.shifts.forEach((s, index) => {
               shifts[`shifts[${ index }].amount`] = (value / 60) * s.minutes;
            })
         }

         return {
            hourlyFee: (PERCENTAGE_FEE / 100) * value,
            hourlyWorkersPart: +(value - ((PERCENTAGE_FEE / 100) * value)).toFixed(2),
            ...shifts
         }
      }
   },
   {
      field: 'hourlyWorkersPart',
      updates: (value, name, allValues, prevValues) => {
         if (!prevValues.hourlyWorkersPart) {
            return {};
         }

         return {
            hourlyRate: +((100 / (100 - PERCENTAGE_FEE)) * value).toFixed(2)
         }
      }
   },
   {
      field: /shifts\[\d+\]\.start/,
      updates: (value, name, allValues, prevValues) => {
         if (!prevValues.shifts) {
            return {};

         }

         const fieldNameEnd = name.replace('.start', '.end');
         const fieldNameMinutes = name.replace('.start', '.minutes');
         const fieldNameMinutesString = name.replace('.start', '.minutesStr');

         if (value === null) {
            return {
               [fieldNameEnd]: null,
               [fieldNameMinutes]: 0,
               [fieldNameMinutesString]: 0
            };
         }

         return {
            [fieldNameEnd]: value ? addMinutes(value, SHIFT_SIZE_MINUTES) : new Date(),
            [fieldNameMinutes]: SHIFT_SIZE_MINUTES,
            [fieldNameMinutesString]: formatMinutes(SHIFT_SIZE_MINUTES)
         };
      }
   },
   {
      field: /shifts\[\d+\]\.end/,
      updates: (value, name, allValues, prevValues) => {
         if (!prevValues.shifts) {
            return {};
         }

         const fieldNameMinutes = name.replace('.end', '.minutes');
         const fieldNameMinutesString = name.replace('.end', '.minutesStr');
         const index = +name.substr(7).split(']')[0];

         const minutes = differenceInMinutes(value, new Date(allValues.shifts[index].start));

         return {
            [fieldNameMinutes]: minutes,
            [fieldNameMinutesString]: formatMinutes(minutes)
         };
      }
   },
   {
      field: /shifts\[\d+\]\.minutesStr/,
      updates: (value, name, allValues, prevValues) => {
         if (!prevValues.shifts) {
            return {};
         }

         const fieldNameMinutes = name.replace('.minutesStr', '.minutes');
         const fieldAmount = name.replace('.minutesStr', '.amount');

         const minutes = parseMinutes(value);

         return {
            [fieldNameMinutes]: minutes,
            [fieldAmount]: (allValues.hourlyRate / 60) * minutes
         };
      }
   },
   {
      field: /.*/,
      updates: (value, name, allValues) => {
         return {
            ...CalculateShiftsPaymentSummary(allValues),
            ...CalculateJobStartEnd(allValues)
         }
      }
   },
)

function JobForm({ onSubmit, initialValues }) {
   const history = useHistory();
   const changed = useSelector((state) => state.jobs.changed);
   const loader = useSelector((state) => state.jobs.jobLoader);
   const loading = useSelector((state) => state.jobs.loader);
   const error = useSelector((state) => state.jobs.error);

   useEffect(() => {
      if (changed) {
         history.push("/jobs");
      }
   }, [changed, history]);

   const isReadOnlyForm = false;


   return (
      <LoadingOrError loading={ loading } error={ error }>
         <Form
            onSubmit={ onSubmit }
            initialValues={ {
               ...{
                  paymentType: PAYMENT_TYPE,
                  hourlyFee: HOURLY_FEE,
                  percentageFee: PERCENTAGE_FEE,
                  hourlyRate: HOURLY_RATE,
                  hourlyWorkersPart: HOURLY_WORKERS_PART
               },
               ...initialValues,
            } }
            mutators={ {
               ...arrayMutators
            } }
            decorators={ [calculator] }
            render={ ({ handleSubmit, form: { mutators: { push, pop } }, values }) => (
               <form onSubmit={ handleSubmit }>
                  <ReadOnlyProvider value={ isReadOnlyForm }>
                     <Card>
                        <CardBody>
                           <JobDetails values={ values }/>
                           <br/>
                           <Row>
                              <Col lg={ 6 } md={ 6 }>
                                 <JobPaymentMethod percentageFee={ values.percentageFee }/>
                              </Col>
                              <Col lg={ 7 } md={ 7 }>
                                 <JobShifts paymentType={ values.paymentType }
                                            hourlyRate={ values.hourlyRate }
                                            push={ push }
                                            pop={ pop }
                                            values={ values }
                                 />
                              </Col>
                              <Col lg={ 5 } md={ 5 }>
                                 <JobShiftsSummary { ...values } />
                              </Col>
                           </Row>
                        </CardBody>
                     </Card>
                  </ReadOnlyProvider>
                  <Link className="btn btn-default btn-fill btn-wd" to="/jobs">
                     CLOSE
                  </Link>
                  {
                     values.status === JOB_STATUSES.NOT_PUBLISHED && (
                        <Button type="submit" color="primary" className="btn-wd pull-right" disabled={ loader }>
                           { loader ? "Saving..." : "Save Job" }
                        </Button>
                     )
                  }
                  {
                     values.status === JOB_STATUSES.NOT_PUBLISHED && (
                        <Button type="button"
                                color="success"
                                onClick={ () => window.confirm('Are you sure, this can\'t be undone...') } // TODO: Not done, use nice UI
                                className="btn-wd pull-right"
                                disabled={ loader }>
                           { loader ? "Saving..." : "Publish Job" }
                        </Button>
                     )
                  }
                  <Pre object={ values }/>
               </form>
            ) }
         />
      </LoadingOrError>
   );
}

export default JobForm;
