import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { getAllWorkers } from "../workers.action";
import { Table, LoadingOrError } from "components";
import { Card, CardBody } from "reactstrap";

function WorkersPage() {
   const workers = useSelector((state) => state.workers.items);
   const loading = useSelector((state) => state.workers.loader);
   const error = useSelector((state) => state.workers.error);

   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(getAllWorkers());
   }, [dispatch]);

   return (
      <LoadingOrError loading={loading} error={error}>
         <Card>
            <CardBody>
               <Table
                  headers={["First Name", "Middle Name", "Last Name", "Phone", "Email", "Address"]}
                  fields={["firstName", "middleName", "lastName", "phone", "email", "address"]}
                  actions={[
                     ({ row }) => (
                        <Link className="btn btn-primary btn-sm btn-fill" to={`/worker/${row._id}`}>
                           Edit
                        </Link>
                     ),
                  ]}
                  rows={workers}
               />
            </CardBody>
         </Card>
         <Link className="btn btn-fill btn-wd" to={"/worker/new"}>
            Add new worker
         </Link>
      </LoadingOrError>
   );
}

export default WorkersPage;
