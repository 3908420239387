import { combineReducers } from 'redux';
import { firebaseReducer as firebase } from 'react-redux-firebase';
import { firestoreReducer as firestore } from 'redux-firestore'

import providers from 'modules/providers/providers.reducer';
import claims from 'modules/auth/claims.reducer';
import users from 'modules/users/users.reducer';
import workers from 'modules/workers/workers.reducer';
import jobs from 'modules/jobs/jobs.reducer';
import dashboard from 'modules/dashboard/dashboard.reducer';

const rootReducer = combineReducers({
   firebase,
   firestore,

   claims,
   users,
   providers,
   workers,
   jobs,
   dashboard
})

export default rootReducer
