import React, { useState, useEffect } from "react";
import classnames from "classnames";
import {
   Button,
   Collapse,
   NavbarBrand,
   Navbar,
   NavItem,
   Nav,
   Container
} from "reactstrap";

import { NavLink, useLocation } from "react-router-dom";
import routes from "routes"

function AdminNavbar({ handleMiniClick }) {
   const [color, setColor] = useState('navbar-transparent');
   const [collapseOpen, setCollapseOpen] = useState(false);

   const location = useLocation();

   useEffect(() => {
      function updateColor() {
         if (window.innerWidth < 993 && collapseOpen) {
            setColor("bg-white");
         } else {
            setColor("navbar-transparent");
         }
      }

      window.addEventListener("resize", updateColor);
   }, [collapseOpen])

   useEffect(() => {
      if (window.innerWidth < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
         document.documentElement.classList.toggle("nav-open");
      }
   }, [location]);

   function toggleCollapse() {
      if (!collapseOpen) {
         setColor("bg-white");
      } else {
         setColor("navbar-transparent");
      }
      setCollapseOpen(!collapseOpen);
   }

   function navbarTitle() {
      const { pathname } = location;
      const route = routes.find(r => r.path === pathname);
      let title = route ? route.name : null;

      if (!title) {
         if (pathname.startsWith('/user/')) {
            title = 'Edit User';
         }
         if (pathname.startsWith('/provider/')) {
            title = 'Edit Provider';
         }
         if (pathname.startsWith('/worker/')) {
            title = 'Edit Worker';
         }
         if (pathname.startsWith('/job/')) {
            title = 'Edit Job';
         }
         if (pathname.startsWith('/jobs/')) {
            title = 'Jobs';
         }
      }

      return title || 'Dashboard';
   }

   return (
      <Navbar className={ classnames("navbar-absolute fixed-top", color) } expand="lg">
         <Container fluid>
            <div className="navbar-wrapper">
               <div className="navbar-minimize">
                  <Button
                     className="btn-icon btn-round"
                     color="default"
                     id="minimizeSidebar"
                     onClick={ handleMiniClick }
                  >
                     <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"/>
                     <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"/>
                  </Button>
               </div>
               <div className="navbar-toggle">
                  <button
                     className="navbar-toggler"
                     type="button"
                     onClick={ () => document.documentElement.classList.toggle("nav-open") }
                  >
                     <span className="navbar-toggler-bar bar1"/>
                     <span className="navbar-toggler-bar bar2"/>
                     <span className="navbar-toggler-bar bar3"/>
                  </button>
               </div>
               <NavbarBrand href="#pablo" onClick={ e => e.preventDefault() }>
                         <span className="d-none d-md-block">
                           { navbarTitle() }
                         </span>
                  <span className="d-block d-md-none">PD PRO React</span>
               </NavbarBrand>
            </div>
            <button
               aria-controls="navigation-index"
               aria-expanded={ collapseOpen }
               aria-label="Toggle navigation"
               className="navbar-toggler"
               data-toggle="collapse"
               type="button"
               onClick={ toggleCollapse }
            >
               <span className="navbar-toggler-bar navbar-kebab"/>
               <span className="navbar-toggler-bar navbar-kebab"/>
               <span className="navbar-toggler-bar navbar-kebab"/>
            </button>
            <Collapse
               className="justify-content-end"
               navbar
               isOpen={ collapseOpen }
            >
               <Nav navbar>
                  <NavItem>
                     <NavLink to="/auth/signout" className="btn-rotate">
                        <i className="nc-icon nc-settings-gear-65"/>
                        <p>
                           <span className="d-lg-none d-md-block">Sign Out</span>
                        </p>
                     </NavLink>
                  </NavItem>
               </Nav>
            </Collapse>
         </Container>
      </Navbar>
   );
}

export default AdminNavbar;
