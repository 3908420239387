import { getFirebase } from "react-redux-firebase";

import { store } from "index.js";

export function AddJob(job) {
   const { firestore } = getFirebase();

   const { claims: { data: { provider_id } } } = store.getState();

   return firestore()
      .collection('providers')
      .doc(provider_id)
      .collection('jobs')
      .add(job);
}

export function UpdateJob({ DocumentID, ...job }) {
   const { firestore } = getFirebase();

   const { claims: { data: { provider_id } } } = store.getState();

   return firestore()
      .collection('providers')
      .doc(provider_id)
      .collection('jobs')
      .doc(DocumentID)
      .update(job)
      .then(function() {
         console.log("Document successfully updated!");
      })
      .catch(function(error) {
         console.error("Error writing document: ", error);
      });
}

