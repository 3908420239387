/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
// import PropTypes from "prop-types";

class Footer extends React.Component {
   render() {
      return (
         <footer
            className={ "footer" + (this.props.default ? " footer-default" : "") }
         >
            <Container fluid={ this.props.fluid ? true : false }>
               <Row>
                  <nav className="footer-nav">
                     <ul>
                        <li>
                           <a href="https://www.balkon.ba">Jeri</a>
                        </li>
                        <li>
                           <a href="https://www.balkon.ba" target="_blank">Licenses</a>
                        </li>
                     </ul>
                  </nav>
                  <div className="credits ml-auto">
                    <span className="copyright">
                      &copy; { 1900 + new Date().getYear() }, made with{ " " }
                       <i className="fa fa-heart heart"/> by BALKON
                    </span>
                  </div>
               </Row>
            </Container>
         </footer>
      );
   }
}

// Footer.propTypes = {
//    default: PropTypes.bool,
//    fluid: PropTypes.bool
// };

export default Footer;
